import * as TYPES from './types';

const initialState = {
    Exports_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltered: 0,
    Export: {}
};

export default function ExportReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.GET_EXPORT_RECEIVE: {
            return {
                ...state,
                Exports_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }

        case TYPES.CREATE_EXPORT_RECEIVE: {
            return {
                ...state,
                Exports_list: [...state.Exports_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        case TYPES.EDIT_EXPORT_RECEIVE: {
            return {
                ...state,
                Export: action.payload,
                Exports_list: state.Exports_list.map((Export) => {
                    if (Export.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return Export;
                    }
                })
            };
        }

        case TYPES.DELETE_EXPORT_RECEIVE: {
            return {
                ...state,
                Exports_list: state.Exports_list.filter((Export) => Export.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        case TYPES.GET_EXPORT_RECEIVE: {
            return {
                ...state,
                Export: action.payload
            };
        }

        default:
            return state;
    }
}
