import * as TYPES from './types';

export const getSparePartsRequest = (payload) => ({
    type: TYPES.GET_SPAREPART_REQUEST,
    payload
});

export const getSparePartsReceive = (payload) => ({
    type: TYPES.GET_SPAREPART_RECEIVE,
    payload
});

export const createSparePartRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_SPAREPART_REQUEST,
    payload,
    closeModal
});
export const createSparePartReceive = (payload) => ({
    type: TYPES.CREATE_SPAREPART_RECEIVE,
    payload
});

export const updateSparePartRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_SPAREPART_REQUEST,
    payload,
    closeModal
});
export const updateSparePartReceive = (payload) => ({
    type: TYPES.EDIT_SPAREPART_RECEIVE,
    payload
});

export const deleteSparePartRequest = (id) => ({
    type: TYPES.DELETE_SPAREPART_REQUEST,
    id
});

export const deleteSparePartReceive = (id) => ({
    type: TYPES.DELETE_SPAREPART_RECEIVE,
    id
});

// get total spare parts
export const getTotalSparePartsRequest = (payload) => ({
    type: TYPES.GET_TOTAL_SPAREPART_REQUEST,
    payload
});
export const getTotalSparePartsReceive = (payload) => ({
    type: TYPES.GET_TOTAL_SPAREPART_RECEIVE,
    payload
});
