import * as TYPES from './types';

// Get Maintenance
export const getMaintenancesRequest = (payload) => ({
    type: TYPES.GET_MAINTENANCE_REQUEST,
    payload
});
export const getMaintenancesReceive = (payload) => ({
    type: TYPES.GET_MAINTENANCE_RECEIVE,
    payload
});

//Create Maintenance
export const createMaintenanceRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_MAINTENANCE_REQUEST,
    payload,
    closeModal
});
export const createMaintenanceReceive = (payload) => ({
    type: TYPES.CREATE_MAINTENANCE_RECEIVE,
    payload
});
//Edit Maintenance
export const editMaintenanceRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_MAINTENANCE_REQUEST,
    payload,
    closeModal
});
export const editMaintenanceReceive = (payload) => ({
    type: TYPES.EDIT_MAINTENANCE_RECEIVE,
    payload
});

//Delete Maintenance
export const deleteMaintenanceRequest = (id) => ({
    type: TYPES.DELETE_MAINTENANCE_REQUEST,
    id
});
export const deleteMaintenanceReceive = (id) => ({
    type: TYPES.DELETE_MAINTENANCE_RECEIVE,
    id
});
