import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

export function* getCategoriesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getCategoriesRequest, payload);

        yield put(ACTIONS.getCategoriesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des catégories');
    }
}

export function* createCategorySaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createCategoryRequest, payload);
        yield put(ACTIONS.createCategoryReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* editCategorySaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editCategoryRequest, payload);
        yield put(ACTIONS.editCategoryReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* deleteCategorySaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteCategoryRequest, id);
        yield put(ACTIONS.deleteCategoryReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// findAllInputCategories
export function* findAllInputCategoriesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.findAllInputCategoriesRequest, payload);

        yield put(ACTIONS.findAllInputCategoriesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des catégories');
    }
}
// findAllOutputCategories
export function* findAllOutputCategoriesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.findAllOutputCategoriesRequest, payload);

        yield put(ACTIONS.findAllOutputCategoriesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des catégories');
    }
}

export function* CategorySagas() {
    yield takeLatest(TYPES.GET_CATEGORIES_REQUEST, getCategoriesSaga);
    yield takeLatest(TYPES.CREATE_CATEGORY_REQUEST, createCategorySaga);
    yield takeLatest(TYPES.DELETE_CATEGORY_REQUEST, deleteCategorySaga);
    yield takeLatest(TYPES.EDIT_CATEGORY_REQUEST, editCategorySaga);
    yield takeLatest(TYPES.FIND_ALL_INPUT_CATEGORIES_REQUEST, findAllInputCategoriesSaga);
    yield takeLatest(TYPES.FIND_ALL_OUTPUT_CATEGORIES_REQUEST, findAllOutputCategoriesSaga);
}
