import * as TYPES from './types';

// Get Suppliers
export const getSuppliersRequest = (payload) => ({
    type: TYPES.GET_SUPPLIERS_REQUEST,
    payload
});
export const getSuppliersReceive = (payload) => ({
    type: TYPES.GET_SUPPLIERS_RECEIVE,
    payload
});
//Create Supplier
export const createSupplierRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_SUPPLIER_REQUEST,
    payload,
    closeModal
});
export const createSupplierReceive = (payload) => ({
    type: TYPES.CREATE_SUPPLIER_RECEIVE,
    payload
});
//Edit Supplier
export const editSupplierRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_SUPPLIER_REQUEST,
    payload,
    closeModal
});
export const editSupplierReceive = (payload) => ({
    type: TYPES.EDIT_SUPPLIER_RECEIVE,
    payload
});

//Delete Supplier
export const deleteSupplierRequest = (id) => ({
    type: TYPES.DELETE_SUPPLIER_REQUEST,
    id
});
export const deleteSupplierReceive = (id) => ({
    type: TYPES.DELETE_SUPPLIER_RECEIVE,
    id
});

// Get Supplier by id
export const getNomSupplierRequest = (id) => ({
    type: TYPES.GET_SUPPLIER_REQUEST,
    id
});
export const getNomSupplierReceive = (id) => ({
    type: TYPES.GET_SUPPLIER_RECEIVE,
    id
});
