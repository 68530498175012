/* eslint-disable no-unused-vars */
// project imports
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

// login routing
const UnauthorizedComponent = Loadable(lazy(() => import('views/pages/UnauthorizedComponent')));

// ==============================|| Unauthorized ROUTING ||============================== //

const UnauthorizedRoute = {
    path: '/',

    children: [
        {
            path: 'unauthorized',
            element: <UnauthorizedComponent />
        }
    ]
};

export default UnauthorizedRoute;
