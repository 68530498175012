import { axiosInstance } from '../../network';

export const getProductsRequest = async (payload, categoryId) => {
    return await axiosInstance.get(`api/product/${categoryId}`, { params: payload });
};

export const createProductRequest = async (payload) => {
    return await axiosInstance.post(`api/product/create`, payload);
};

export const editProductRequest = async (payload) => {
    return await axiosInstance.patch(`api/product/${payload.id}`, payload);
};

export const deleteProductRequest = async (id) => {
    return await axiosInstance.delete(`api/product/delete/${id}`);
};

// get all products
export const getAllProductsRequest = async (payload) => {
    return await axiosInstance.get(`api/product`, { params: payload });
};

// get output products
export const getOutputProductsRequest = async (payload, categoryId) => {
    return await axiosInstance.get(`api/product/${categoryId}`, { params: payload });
};

// get input products
export const getInputProductsRequest = async (payload, categoryId) => {
    return await axiosInstance.get(`api/product/${categoryId}`, { params: payload });
};

// get total products
export const getTotalProductsRequest = async (payload) => {
    return await axiosInstance.get(`api/product/total/count`, { params: payload });
};

// get products out of stock
export const getProductsOutOfStockRequest = async (payload) => {
    return await axiosInstance.get(`api/product/total/countLessThen50`, { params: payload });
};
