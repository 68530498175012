import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

// get output products
export function* getOutputProductsSaga({ payload, categoryId }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getOutputProductsRequest, payload, categoryId);

        yield put(ACTIONS.getOutputProductsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}
// get input products
export function* getInputProductsSaga({ payload, categoryId }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getInputProductsRequest, payload, categoryId);
        yield put(ACTIONS.getInputProductsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}

// get all products
export function* getAllProductsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getAllProductsRequest, payload);
        yield put(ACTIONS.getAllProductsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}

export function* getProductSaga({ payload, categoryId }) {
    try {
        yield put(setLoadingStatus(true));

        const { data } = yield call(APIS.getProductsRequest, payload, categoryId);

        yield put(ACTIONS.getProductsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}

export function* createProductSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createProductRequest, payload);
        yield put(ACTIONS.createProductReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* editProductSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editProductRequest, payload);
        yield put(ACTIONS.editProductReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* deleteProductSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteProductRequest, id);
        yield put(ACTIONS.deleteProductReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// get total products
export function* getTotalProductsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTotalProductsRequest, payload);
        yield put(ACTIONS.getTotalProductsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}

// get products out of stock
export function* getProductsOutOfStockSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getProductsOutOfStockRequest, payload);
        yield put(ACTIONS.getProductsOutOfStockReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}

export function* ProductSagas() {
    yield takeLatest(TYPES.GET_PRODUCTS_REQUEST, getProductSaga);
    yield takeLatest(TYPES.CREATE_PRODUCT_REQUEST, createProductSaga);
    yield takeLatest(TYPES.DELETE_PRODUCT_REQUEST, deleteProductSaga);
    yield takeLatest(TYPES.EDIT_PRODUCT_REQUEST, editProductSaga);
    yield takeLatest(TYPES.GET_ALL_PRODUCTS_REQUEST, getAllProductsSaga);
    yield takeLatest(TYPES.GET_INPUT_PRODUCTS_REQUEST, getInputProductsSaga);
    yield takeLatest(TYPES.GET_OUTPUT_PRODUCTS_REQUEST, getOutputProductsSaga);
    yield takeLatest(TYPES.GET_TOTAL_PRODUCTS_REQUEST, getTotalProductsSaga);
    yield takeLatest(TYPES.GET_PRODUCTS_OUT_OF_STOCK_REQUEST, getProductsOutOfStockSaga);
}
