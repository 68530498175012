import * as TYPES from './types';

// findAllInputCategories
export const findAllInputCategoriesRequest = (payload) => ({
    type: TYPES.FIND_ALL_INPUT_CATEGORIES_REQUEST,
    payload
});
export const findAllInputCategoriesReceive = (payload) => ({
    type: TYPES.FIND_ALL_INPUT_CATEGORIES_RECEIVE,
    payload
});
// findAllOutputCategories
export const findAllOutputCategoriesRequest = (payload) => ({
    type: TYPES.FIND_ALL_OUTPUT_CATEGORIES_REQUEST,
    payload
});
export const findAllOutputCategoriesReceive = (payload) => ({
    type: TYPES.FIND_ALL_OUTPUT_CATEGORIES_RECEIVE,
    payload
});

// Get Categories
export const getCategoriesRequest = (payload) => ({
    type: TYPES.GET_CATEGORIES_REQUEST,
    payload
});
export const getCategoriesReceive = (payload) => ({
    type: TYPES.GET_CATEGORIES_RECEIVE,
    payload
});
//Create Category
export const createCategoryRequest = (payload) => ({
    type: TYPES.CREATE_CATEGORY_REQUEST,
    payload
});
export const createCategoryReceive = (payload) => ({
    type: TYPES.CREATE_CATEGORY_RECEIVE,
    payload
});
//Edit Category
export const editCategoryRequest = (payload) => ({
    type: TYPES.EDIT_CATEGORY_REQUEST,
    payload
});
export const editCategoryReceive = (payload) => ({
    type: TYPES.EDIT_CATEGORY_RECEIVE,
    payload
});

//Delete Category
export const deleteCategoryRequest = (id) => ({
    type: TYPES.DELETE_CATEGORY_REQUEST,
    id
});
export const deleteCategoryReceive = (id) => ({
    type: TYPES.DELETE_CATEGORY_RECEIVE,
    id
});
