import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Partners
export function* getPartnersSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPartnersRequest, payload);

        yield put(ACTIONS.getPartnersReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des Partners');
    }
}

// Create Partner
export function* createPartnersaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createPartnerRequest, payload);
        yield put(ACTIONS.createPartnerReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit Partner
export function* editPartnersaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editPartnerRequest, payload);
        yield put(ACTIONS.editPartnerReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Partner
export function* deletePartnersaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deletePartnerRequest, id);
        yield put(ACTIONS.deletePartnerReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get nom Partner
export function* getPartnersaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getNomPartnerRequest, payload);
        yield put(ACTIONS.getNomPartnerReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* PartnersSagas() {
    yield takeLatest(TYPES.GET_PARTNERS_REQUEST, getPartnersSaga);
    yield takeLatest(TYPES.CREATE_PARTNER_REQUEST, createPartnersaga);
    yield takeLatest(TYPES.DELETE_PARTNER_REQUEST, deletePartnersaga);
    yield takeLatest(TYPES.GET_PARTNER_REQUEST, getPartnersaga);
    yield takeLatest(TYPES.EDIT_PARTNER_REQUEST, editPartnersaga);
}
