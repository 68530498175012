export const GET_MAINTENANCE_REQUEST = 'GET_MAINTENANCE_REQUEST';
export const GET_MAINTENANCE_RECEIVE = 'GET_MAINTENANCE_RECEIVE';

export const CREATE_MAINTENANCE_REQUEST = 'CREATE_MAINTENANCE_REQUEST';
export const CREATE_MAINTENANCE_RECEIVE = 'CREATE_MAINTENANCE_RECEIVE';

export const EDIT_MAINTENANCE_REQUEST = 'EDIT_MAINTENANCE_REQUEST';
export const EDIT_MAINTENANCE_RECEIVE = 'EDIT_MAINTENANCE_RECEIVE';

export const DELETE_MAINTENANCE_REQUEST = 'DELETE_MAINTENANCE_REQUEST';
export const DELETE_MAINTENANCE_RECEIVE = 'DELETE_MAINTENANCE_RECEIVE';
