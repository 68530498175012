import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { getUserDataRequest } from 'store/users/actions';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/home', { replace: true });
        }
    }, [token, navigate, children]);

    useEffect(() => {
        const localUserData = JSON.parse(localStorage.getItem('user'));
        if (localUserData) {
            dispatch(getUserDataRequest(localUserData.id));
        }
    }, [dispatch]);

    return children;
};

export default AuthGuard;
