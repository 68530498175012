/* eslint-disable no-unused-vars */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormFournisseur from './FormOrder';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { editOrderRequest } from 'store/Order/actions';

function EditFournisseur({ open, close, Partner }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ ...Partner });
    const [formErrors, setFormErrors] = useState({});
    const validateForm = () => {
        let errors = {};
        // if (!formData.company) {
        //     errors.company = 'champ obligatoire';
        // }
        // if (!formData.manufacture) {
        //     errors.manufacture = 'champ obligatoire';
        // }

        return errors;
    };

    const handleSetFormData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const closeModal = () => {
        setFormData({});
        close();
    };
    const handleUpdate = () => {
        const errors = validateForm();
        if (Object.keys(errors).length == 0) {
            dispatch(editOrderRequest(formData, closeModal));
        } else {
            setFormErrors(errors);
        }
    };

    return (
        <Dialog open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <DialogTitle id="modal-modal-title" sx={{ padding: '20px', fontSize: '20px', textAlign: 'center' }}>
                {t('EditOrder')}
            </DialogTitle>
            <DialogContent sx={{ padding: { xs: '15px', sm: '25px', md: '40px' } }}>
                <br />
                <FormFournisseur formData={formData} formErrors={formErrors} handleSetFormData={(e) => handleSetFormData(e)} />
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        backgroundColor: '#2c2d2e',
                        color: 'white',
                        '&:hover': {
                            background: ' #525861'
                        }
                    }}
                    onClick={close}
                    variant="primary"
                >
                    {t('Cancel')}
                </Button>
                <Button
                    sx={{
                        backgroundColor: '#049737',
                        color: 'white',
                        '&:hover': {
                            background: ' #06bd45'
                        }
                    }}
                    onClick={handleUpdate}
                    variant="primary"
                >
                    {t('Confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditFournisseur.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    fournisseur: PropTypes.object
};

export default EditFournisseur;
