import * as TYPES from './types';

const initialState = {
    users: [],
    userData: {},
    userInfo: {},
    usersStatistics: {},
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    usersList: []
};

export default function usersReducer(state = initialState, action) {
    switch (action.type) {
        //Get Users
        case TYPES.GET_USERS_RECEIVE: {
            return {
                ...state,
                users: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get UserData
        case TYPES.GET_USER_DATA_RECEIVE: {
            return {
                ...state,
                userData: action.payload
            };
        }
        //Add User
        case TYPES.ADD_USER_RECEIVE: {
            return {
                ...state,
                users: [...state.users, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit User
        case TYPES.EDIT_USER_RECEIVE: {
            return {
                ...state,
                userInfo: action.payload,
                users: state.users.map((user) => {
                    if (user.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return user;
                    }
                })
            };
        }
        //Delete User
        case TYPES.DELETE_USER_RECEIVE: {
            return {
                ...state,
                users: state.users.filter((user) => user.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //Get Users List
        case TYPES.GET_USERS_LIST_RECEIVE: {
            return {
                ...state,
                usersList: action.payload
            };
        }

        //Get Users Statistics
        case TYPES.GET_USERS_STATISTICS_RECEIVE: {
            return {
                ...state,
                usersStatistics: action.payload
            };
        }
        //Set Iniiial State
        case TYPES.SET_INITIAL_STATE: {
            return initialState;
        }
        default:
            return state;
    }
}
