import { Outlet } from 'react-router-dom';

// project imports

import { MaterialSnackbar } from 'ui-component/Snackbar';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <MaterialSnackbar />
        <Outlet />
    </>
);

export default MinimalLayout;
