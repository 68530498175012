/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import React from 'react';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Grid, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import LanguageIcon from '@mui/icons-material/Language';
// assets
import MenuIcon from '@mui/icons-material/Menu';
import { IconChevronRight } from '@tabler/icons';
import { setMenuRequest } from 'store/theme/actions';
import { drawerWidth } from 'utils/Constants';
import { MaterialSnackbar } from 'ui-component/Snackbar';
import LanguageSelector from 'layout/LanguageSelector';
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 40,

    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 5,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.theme.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch(setMenuRequest({ opened: !leftDrawerOpened }));
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <Box sx={{ display: 'flex' }}>
            <MaterialSnackbar />

            <CssBaseline />
            {
                <Box
                    sx={{
                        width: 10,
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            width: '5%'
                        }
                    }}
                >
                    <MenuIcon
                        stroke={1.5}
                        fontSize="large"
                        sx={{
                            color: '#049734',
                            '&:hover': {
                                color: '#20E55F'
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                    />
                </Box>
            }
            <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            <Main theme={theme} open={leftDrawerOpened}>
                <Outlet />
            </Main>
            <Grid item xs={12} sm={6} md={8}>
                <LanguageSelector />
            </Grid>
        </Box>
    );
};

export default MainLayout;
