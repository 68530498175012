import { axiosInstance } from '../../network';

//Get output
export const getOutPutRequest = async (payload) => {
    return await axiosInstance.get(`api/planning/outPut`, { params: payload });
};

//Get Plannings
export const getPlanningsRequest = async (payload) => {
    return await axiosInstance.get(`api/planning`, { params: payload });
};
//Create planning
export const createPlanningRequest = async (payload) => {
    return await axiosInstance.post(`api/planning/create`, payload);
};

// get all plannings by partner Id
export const getPlanningsByPartnerIdRequest = async (id) => {
    return await axiosInstance.get(`api/planning/partner/${id}`);
};
//update planning by id
export const updatePlanningByIdRequest = async (payload) => {
    return await axiosInstance.patch(`api/planning/update/${payload.id}`, payload);
};

// delete planning by id
export const deletePlanningByIdRequest = async (id) => {
    return await axiosInstance.delete(`api/planning/delete/${id}`);
};

// get outPut Product by category
export const getOutPutProductByCategoryRequest = async (payload) => {
    return await axiosInstance.get(`api/planning/outPut/${payload.category.id}`, { params: payload });
};

// get total plannings
export const getTotalPlanningsRequest = async (payload) => {
    return await axiosInstance.get(`api/planning/total`, { params: payload });
};

// get LastInput
export const getLastInputRequest = async (payload) => {
    return await axiosInstance.get(`api/planning/LastInput`, { params: payload });
};
