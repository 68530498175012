export const GET_SPAREPART_REQUEST = 'GET_SPAREPART_REQUEST';
export const GET_SPAREPART_RECEIVE = 'GET_SPAREPART_RECEIVE';

export const CREATE_SPAREPART_REQUEST = 'CREATE_SPAREPART_REQUEST';
export const CREATE_SPAREPART_RECEIVE = 'CREATE_SPAREPART_RECEIVE';

export const EDIT_SPAREPART_REQUEST = 'EDIT_SPAREPART_REQUEST';
export const EDIT_SPAREPART_RECEIVE = 'EDIT_SPAREPART_RECEIVE';

export const DELETE_SPAREPART_REQUEST = 'DELETE_SPAREPART_REQUEST';
export const DELETE_SPAREPART_RECEIVE = 'DELETE_SPAREPART_RECEIVE';

// get total spare parts
export const GET_TOTAL_SPAREPART_REQUEST = 'GET_TOTAL_SPAREPART_REQUEST';
export const GET_TOTAL_SPAREPART_RECEIVE = 'GET_TOTAL_SPAREPART_RECEIVE';

// get LastInput
export const GET_LAST_INPUT_REQUEST = 'GET_LAST_INPUT_REQUEST';
export const GET_LAST_INPUT_RECEIVE = 'GET_LAST_INPUT_RECEIVE';
