import * as TYPES from './types';

export const getExportRequest = (payload) => ({
    type: TYPES.GET_EXPORT_REQUEST,
    payload
});
export const getExportReceive = (payload) => ({
    type: TYPES.GET_EXPORT_RECEIVE,
    payload
});

export const createExportRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_EXPORT_REQUEST,
    payload,
    closeModal
});
export const createExportReceive = (payload) => ({
    type: TYPES.CREATE_EXPORT_RECEIVE,
    payload
});

export const editExportRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_EXPORT_REQUEST,
    payload,
    closeModal
});
export const editExportReceive = (payload) => ({
    type: TYPES.EDIT_EXPORT_RECEIVE,
    payload
});

export const deleteExportRequest = (id) => ({
    type: TYPES.DELETE_EXPORT_REQUEST,
    id
});
export const deleteExportReceive = (id) => ({
    type: TYPES.DELETE_EXPORT_RECEIVE,
    id
});
