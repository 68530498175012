import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import UnauthorizedRoute from './UnauthorizedRoute';
import HomeRoute from './HomeRoute';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, UnauthorizedRoute, AuthenticationRoutes, HomeRoute, LoginRoutes]);
}
