export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_RECEIVE = 'GET_CATEGORIES_RECEIVE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_RECEIVE = 'CREATE_CATEGORY_RECEIVE';

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_RECEIVE = ' EDIT_CATEGORY_RECEIVE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_RECEIVE = 'DELETE_CATEGORY_RECEIVE';

// findAllInputCategories
export const FIND_ALL_INPUT_CATEGORIES_REQUEST = 'FIND_ALL_INPUT_CATEGORIES_REQUEST';
export const FIND_ALL_INPUT_CATEGORIES_RECEIVE = 'FIND_ALL_INPUT_CATEGORIES_RECEIVE';

// findAllOutputCategories
export const FIND_ALL_OUTPUT_CATEGORIES_REQUEST = 'FIND_ALL_OUTPUT_CATEGORIES_REQUEST';
export const FIND_ALL_OUTPUT_CATEGORIES_RECEIVE = 'FIND_ALL_OUTPUT_CATEGORIES_RECEIVE';
