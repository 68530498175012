import * as TYPES from './types';

//get output products
export const getOutputProductsRequest = (payload, categoryId) => ({
    type: TYPES.GET_OUTPUT_PRODUCTS_REQUEST,
    payload,
    categoryId
});
export const getOutputProductsReceive = (payload, categoryId) => ({
    type: TYPES.GET_OUTPUT_PRODUCTS_RECEIVE,
    payload,
    categoryId
});
//get input products
export const getInputProductsRequest = (payload, categoryId) => ({
    type: TYPES.GET_INPUT_PRODUCTS_REQUEST,
    payload,
    categoryId
});
export const getInputProductsReceive = (payload, categoryId) => ({
    type: TYPES.GET_INPUT_PRODUCTS_RECEIVE,
    payload,
    categoryId
});

// get all products
export const getAllProductsRequest = (payload) => ({
    type: TYPES.GET_ALL_PRODUCTS_REQUEST,
    payload
});
export const getAllProductsReceive = (payload) => ({
    type: TYPES.GET_ALL_PRODUCTS_RECEIVE,
    payload
});

// Get Products
export const getProductsRequest = (payload, categoryId) => ({
    type: TYPES.GET_PRODUCTS_REQUEST,
    payload,
    categoryId
});
export const getProductsReceive = (payload, categoryId) => ({
    type: TYPES.GET_PRODUCTS_RECEIVE,
    payload,
    categoryId
});
//Create Product
export const createProductRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_PRODUCT_REQUEST,
    payload,
    closeModal
});
export const createProductReceive = (payload) => ({
    type: TYPES.CREATE_PRODUCT_RECEIVE,
    payload
});
//Edit Product
export const editProductRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_PRODUCT_REQUEST,
    payload,
    closeModal
});
export const editProductReceive = (payload) => ({
    type: TYPES.EDIT_PRODUCT_RECEIVE,
    payload
});

//Delete Product
export const deleteProductRequest = (id) => ({
    type: TYPES.DELETE_PRODUCT_REQUEST,
    id
});
export const deleteProductReceive = (id) => ({
    type: TYPES.DELETE_PRODUCT_RECEIVE,
    id
});

// get total products
export const getTotalProductsRequest = (payload) => ({
    type: TYPES.GET_TOTAL_PRODUCTS_REQUEST,
    payload
});
export const getTotalProductsReceive = (payload) => ({
    type: TYPES.GET_TOTAL_PRODUCTS_RECEIVE,
    payload
});

// get products out of stock
export const getProductsOutOfStockRequest = (payload) => ({
    type: TYPES.GET_PRODUCTS_OUT_OF_STOCK_REQUEST,
    payload
});
export const getProductsOutOfStockReceive = (payload) => ({
    type: TYPES.GET_PRODUCTS_OUT_OF_STOCK_RECEIVE,
    payload
});
