import * as TYPES from './types';

// Get Orders
export const getOrdersRequest = (payload) => ({
    type: TYPES.GET_ORDERS_REQUEST,
    payload
});
export const getOrdersReceive = (payload) => ({
    type: TYPES.GET_ORDERS_RECEIVE,
    payload
});
//Create Order
export const createOrderRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_ORDER_REQUEST,
    payload,
    closeModal
});
export const createOrderReceive = (payload) => ({
    type: TYPES.CREATE_ORDER_RECEIVE,
    payload
});
//Edit Order
export const editOrderRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_ORDER_REQUEST,
    payload,
    closeModal
});
export const editOrderReceive = (payload) => ({
    type: TYPES.EDIT_ORDER_RECEIVE,
    payload
});

//Delete Order
export const deleteOrderRequest = (id) => ({
    type: TYPES.DELETE_ORDER_REQUEST,
    id
});
export const deleteOrderReceive = (id) => ({
    type: TYPES.DELETE_ORDER_RECEIVE,
    id
});

// Get Order by id
export const getNomOrderRequest = (id) => ({
    type: TYPES.GET_ORDER_REQUEST,
    id
});
export const getNomOrderReceive = (id) => ({
    type: TYPES.GET_ORDER_RECEIVE,
    id
});
