// get Suppliers
export const GET_SUPPLIERS_REQUEST = 'GET_SUPPLIERS_REQUEST';
export const GET_SUPPLIERS_RECEIVE = 'GET_SUPPLIERS_RECEIVE';
//create Supplier
export const CREATE_SUPPLIER_REQUEST = 'CREATE_SUPPLIER_REQUEST';
export const CREATE_SUPPLIER_RECEIVE = 'CREATE_SUPPLIER_RECEIVE';
// Edit Supplier
export const EDIT_SUPPLIER_REQUEST = 'EDIT_SUPPLIER_REQUEST';
export const EDIT_SUPPLIER_RECEIVE = ' EDIT_SUPPLIER_RECEIVE';
// delete Supplier
export const DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST';
export const DELETE_SUPPLIER_RECEIVE = 'DELETE_SUPPLIER_RECEIVE';
// get Supplier by id
export const GET_SUPPLIER_REQUEST = 'GET_SUPPLIER_REQUEST';
export const GET_SUPPLIER_RECEIVE = 'GET_SUPPLIER_RECEIVE';
