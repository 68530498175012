import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

export function* getExportSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getExportRequest, payload);

        yield put(ACTIONS.getExportReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des Export');
    }
}

export function* createExportaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createExportRequest, payload);
        yield put(ACTIONS.createExportReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* editExportaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editExportRequest, payload);
        yield put(ACTIONS.editExportReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* deleteExportaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteExportRequest, id);
        yield put(ACTIONS.deleteExportReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* ExportSagas() {
    yield takeLatest(TYPES.GET_EXPORT_REQUEST, getExportSaga);
    yield takeLatest(TYPES.CREATE_EXPORT_REQUEST, createExportaga);
    yield takeLatest(TYPES.DELETE_EXPORT_REQUEST, deleteExportaga);
    yield takeLatest(TYPES.EDIT_EXPORT_REQUEST, editExportaga);
}
