// get Partners
export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST';
export const GET_PARTNERS_RECEIVE = 'GET_PARTNERS_RECEIVE';
//create Partner
export const CREATE_PARTNER_REQUEST = 'CREATE_PARTNER_REQUEST';
export const CREATE_PARTNER_RECEIVE = 'CREATE_PARTNER_RECEIVE';
// Edit Partner
export const EDIT_PARTNER_REQUEST = 'EDIT_PARTNER_REQUEST';
export const EDIT_PARTNER_RECEIVE = ' EDIT_PARTNER_RECEIVE';
// delete Partner
export const DELETE_PARTNER_REQUEST = 'DELETE_PARTNER_REQUEST';
export const DELETE_PARTNER_RECEIVE = 'DELETE_PARTNER_RECEIVE';
// get Partner by id
export const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';
export const GET_PARTNER_RECEIVE = 'GET_PARTNER_RECEIVE';
