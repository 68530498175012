import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

export function* getMaintenancesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getMaintenanceRequest, payload);

        yield put(ACTIONS.getMaintenancesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* createMaintenanceSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createMaintenanceRequest, payload);
        yield put(ACTIONS.createMaintenanceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* editMaintenanceSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editMaintenanceRequest, payload);
        yield put(ACTIONS.editMaintenanceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* deleteMaintenanceSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteMaintenanceRequest, id);
        yield put(ACTIONS.deleteMaintenanceReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* maintenancesSagas() {
    yield takeLatest(TYPES.GET_MAINTENANCE_REQUEST, getMaintenancesSaga);
    yield takeLatest(TYPES.CREATE_MAINTENANCE_REQUEST, createMaintenanceSaga);
    yield takeLatest(TYPES.DELETE_MAINTENANCE_REQUEST, deleteMaintenanceSaga);
    yield takeLatest(TYPES.EDIT_MAINTENANCE_REQUEST, editMaintenanceSaga);
}
