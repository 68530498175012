import * as TYPES from './types';

const initialState = {
    Products_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    Products: {},
    AllProducts: [],
    ProductsOutPut: [],
    ProductsInput: [],
    totalProducts: 0,
    outOfStock: []
};

export default function ProductsReducer(state = initialState, action) {
    switch (action.type) {
        //get output products
        case TYPES.GET_OUTPUT_PRODUCTS_RECEIVE: {
            return {
                ...state,
                ProductsOutPut: action.payload.data,
                pageCount: parseInt(action.payload.total / 10),
                currentPage: parseInt(action.payload.currentPage),
                totalFiltred: parseInt(action.payload.total)
            };
        }
        // get input products
        case TYPES.GET_INPUT_PRODUCTS_RECEIVE: {
            return {
                ...state,
                ProductsInput: action.payload.data,
                pageCount: parseInt(action.payload.total / 10),
                currentPage: parseInt(action.payload.currentPage),
                totalFiltred: parseInt(action.payload.total)
            };
        }

        // get all products
        case TYPES.GET_ALL_PRODUCTS_RECEIVE: {
            return {
                ...state,
                AllProducts: action.payload
            };
        }

        case TYPES.GET_PRODUCTS_RECEIVE: {
            return {
                ...state,
                Products_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }

        case TYPES.CREATE_PRODUCT_RECEIVE: {
            return {
                ...state,
                Products_list: [...state.Products_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        case TYPES.EDIT_PRODUCT_RECEIVE: {
            return {
                ...state,
                Products: action.payload,
                Products_list: state.Products_list.map((Products) => {
                    if (Products.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return Products;
                    }
                })
            };
        }

        case TYPES.DELETE_PRODUCT_RECEIVE: {
            return {
                ...state,
                Products_list: state.Products_list.filter((Products) => Products.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        // get total products
        case TYPES.GET_TOTAL_PRODUCTS_RECEIVE: {
            return {
                ...state,
                totalProducts: action.payload
            };
        }

        // get products out of stock
        case TYPES.GET_PRODUCTS_OUT_OF_STOCK_RECEIVE: {
            return {
                ...state,
                outOfStock: action.payload
            };
        }

        default:
            return state;
    }
}
