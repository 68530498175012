import * as TYPES from './types';

// Get output
export const getOutPutRequest = (payload) => ({
    type: TYPES.GET_OUTPUT_REQUEST,
    payload
});
export const getOutPutReceive = (payload) => ({
    type: TYPES.GET_OUTPUT_RECEIVE,
    payload
});

// Get Plannings
export const getPlanningsRequest = (payload) => ({
    type: TYPES.GET_PLANNINGS_REQUEST,
    payload
});
export const getPlanningsReceive = (payload) => ({
    type: TYPES.GET_PLANNINGS_RECEIVE,
    payload
});

// create planning
export const createPlanningRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_PLANNING_REQUEST,
    payload,
    closeModal
});
export const createPlanningReceive = (payload) => ({
    type: TYPES.CREATE_PLANNING_RECEIVE,
    payload
});

// get all plannings by partner id
export const getPlanningsByPartnerIdRequest = (id) => ({
    type: TYPES.GET_PLANNINGS_BY_PARTNER_ID_REQUEST,
    id
});
export const getPlanningsByPartnerIdReceive = (payload) => ({
    type: TYPES.GET_PLANNINGS_BY_PARTNER_ID_RECEIVE,
    payload
});

// update planning by id
export const updatePlanningByIdRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_PLANNING_BY_ID_REQUEST,
    payload,
    closeModal
});
export const updatePlanningByIdReceive = (payload) => ({
    type: TYPES.UPDATE_PLANNING_BY_ID_RECEIVE,
    payload
});

// delete planning by id
export const deletePlanningByIdRequest = (id, setOpenDeleteConfirmation) => ({
    type: TYPES.DELETE_PLANNING_BY_ID_REQUEST,
    id,
    setOpenDeleteConfirmation
});
export const deletePlanningByIdReceive = (id) => ({
    type: TYPES.DELETE_PLANNING_BY_ID_RECEIVE,
    id
});

// get OutPutProducts by category
export const getOutPutProductsByCategoryRequest = (payload) => ({
    type: TYPES.GET_OUTPUT_PRODUCT_BY_CATEGORY_REQUEST,
    payload
});
export const getOutPutProductsByCategoryReceive = (payload) => ({
    type: TYPES.GET_OUTPUT_PRODUCT_BY_CATEGORY_RECEIVE,
    payload
});

// get total plannings
export const getTotalPlanningsRequest = (payload) => ({
    type: TYPES.GET_TOTAL_PLANNING_REQUEST,
    payload
});
export const getTotalPlanningsReceive = (payload) => ({
    type: TYPES.GET_TOTAL_PLANNING_RECEIVE,
    payload
});

// get LastInput
export const getLastInputRequest = (payload) => ({
    type: TYPES.GET_LAST_INPUT_REQUEST,
    payload
});
export const getLastInputReceive = (payload) => ({
    type: TYPES.GET_LAST_INPUT_RECEIVE,
    payload
});
