import { axiosInstance } from '../../network';

export const getCategoriesRequest = async (payload) => {
    return await axiosInstance.get(`api/category`, { params: payload });
};

export const createCategoryRequest = async (payload) => {
    return await axiosInstance.post(`api/category/create`, payload);
};

export const editCategoryRequest = async (payload) => {
    return await axiosInstance.patch(`api/category/${payload.id}`, payload);
};

export const deleteCategoryRequest = async (id) => {
    return await axiosInstance.delete(`api/category/delete/${id}`);
};

// findAllInputCategories
export const findAllInputCategoriesRequest = async (payload) => {
    return await axiosInstance.get(`api/category/input`, { params: payload });
};

// findAllOutputCategories
export const findAllOutputCategoriesRequest = async (payload) => {
    return await axiosInstance.get(`api/category/output`, { params: payload });
};
