import { axiosInstance } from '../../network';

//Get Productions
export const getProductionsRequest = async (payload) => {
    return await axiosInstance.get(`api/production`, { params: payload });
};

//Create Production
export const createProductionRequest = async (payload) => {
    return await axiosInstance.post(`api/production/create`, payload);
};
//Delete Production
export const deleteProductionRequest = async (id, name) => {
    return await axiosInstance.delete(`api/production/delete/${id}?name=${name}`);
};

//Edit Production
export const editProductionRequest = async (payload) => {
    return await axiosInstance.patch(`api/production/update/${payload.id}`, payload.data);
};
