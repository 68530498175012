import { axiosInstance } from '../../network';

export const getExportRequest = async (payload) => {
    return await axiosInstance.get(`/api/Export/`, { params: payload });
};

export const createExportRequest = async (payload) => {
    return await axiosInstance.post(`/api/Export/create`, payload);
};

export const editExportRequest = async (payload) => {
    return await axiosInstance.patch(`/api/Export/${payload.id}`, payload);
};

export const deleteExportRequest = async (id) => {
    return await axiosInstance.delete(`/api/Export/delete/${id}`);
};
