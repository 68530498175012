import * as TYPES from './types';

const initialState = {
    maintenance_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    maintenance: {}
};

export default function maintenancesReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.GET_MAINTENANCE_RECEIVE: {
            return {
                ...state,
                maintenance_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }

        case TYPES.CREATE_MAINTENANCE_RECEIVE: {
            return {
                ...state,
                maintenance_list: [...state.maintenance_list, action?.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        case TYPES.EDIT_MAINTENANCE_RECEIVE: {
            return {
                ...state,
                maintenance: action.payload,
                maintenance_list: state.maintenance_list.map((maintenance) => {
                    if (maintenance.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return maintenance;
                    }
                })
            };
        }

        case TYPES.DELETE_MAINTENANCE_RECEIVE: {
            return {
                ...state,
                maintenance_list: state.maintenance_list.filter((maintenance) => maintenance.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
