/* eslint-disable no-unused-vars */
import { Box, Stack, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

function FilterContainer({ payload, setPayload }) {
    const { t } = useTranslation();
    const { search } = payload;
    const handleSearch = (e) => {
        setPayload({
            search: e.target.value
        });
    };

    return (
        <Stack>
            <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                <TextField label={t('Product')} name="search" onChange={handleSearch} value={search} variant="outlined" />

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        marginLeft: '10px',
                        width: '46px',
                        height: '46px',
                        borderRadius: '9px',
                        backgroundColor: 'black'
                    }}
                >
                    <SearchIcon sx={{ color: 'white' }} />
                </Box>
            </Box>
        </Stack>
    );
}

FilterContainer.propTypes = {
    payload: PropTypes.object.isRequired,
    setPayload: PropTypes.func.isRequired
};

export default FilterContainer;
