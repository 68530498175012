import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Suppliers
export function* getSuppliersSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getSuppliersRequest, payload);

        yield put(ACTIONS.getSuppliersReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des Suppliers');
    }
}

// Create Supplier
export function* createSupplierSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createSupplierRequest, payload);
        yield put(ACTIONS.createSupplierReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit Supplier
export function* editSupplierSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editSupplierRequest, payload);
        yield put(ACTIONS.editSupplierReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Supplier
export function* deleteSupplierSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteSupplierRequest, id);
        yield put(ACTIONS.deleteSupplierReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get nom Supplier
export function* getSupplierSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getNomSupplierRequest, payload);
        yield put(ACTIONS.getNomSupplierReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* SuppliersSagas() {
    yield takeLatest(TYPES.GET_SUPPLIERS_REQUEST, getSuppliersSaga);
    yield takeLatest(TYPES.CREATE_SUPPLIER_REQUEST, createSupplierSaga);
    yield takeLatest(TYPES.DELETE_SUPPLIER_REQUEST, deleteSupplierSaga);
    yield takeLatest(TYPES.GET_SUPPLIER_REQUEST, getSupplierSaga);
    yield takeLatest(TYPES.EDIT_SUPPLIER_REQUEST, editSupplierSaga);
}
