import * as TYPES from './types';
const initialState = {
    Categories_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    Categories: {},
    OutputCategories: [],
    InputCategories: []
};

export default function CategoriesReducer(state = initialState, action) {
    switch (action.type) {
        // findAllInputCategories
        case TYPES.FIND_ALL_INPUT_CATEGORIES_RECEIVE: {
            return {
                ...state,
                InputCategories: action?.payload
            };
        }
        // findAllOutputCategories
        case TYPES.FIND_ALL_OUTPUT_CATEGORIES_RECEIVE: {
            return {
                ...state,
                OutputCategories: action?.payload
            };
        }
        case TYPES.GET_CATEGORIES_RECEIVE: {
            return {
                ...state,
                Categories_list: action?.payload,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }

        case TYPES.CREATE_CATEGORY_RECEIVE: {
            return {
                ...state,
                Categories_list: [...state.Categories_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        case TYPES.EDIT_CATEGORY_RECEIVE: {
            return {
                ...state,
                Categories: action.payload,
                Categories_list: state.Categories_list.map((Categories) => {
                    if (Categories.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return Categories;
                    }
                })
            };
        }

        case TYPES.DELETE_CATEGORY_RECEIVE: {
            return {
                ...state,
                Categories_list: state.Categories_list.filter((Categories) => Categories.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
