/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { element } from 'prop-types';
import Orders from 'views/pages/Orders/OrderPage';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Suppliers = Loadable(lazy(() => import('views/pages/Suppliers/SuppliersPage')));
const Purchase = Loadable(lazy(() => import('views/pages/Purchase/PurchasePage')));
const Partners = Loadable(lazy(() => import('views/pages/Partners/PartnersPage')));
const Planning = Loadable(lazy(() => import('views/pages/Planning/PlanningPage')));
const SpareParts = Loadable(lazy(() => import('views/pages/SpareParts/SparePartsPage')));
const InternalUsers = Loadable(lazy(() => import('views/pages/users/InternalUsers/InternalUsers')));
const EventsPage = Loadable(lazy(() => import('views/pages/events/EventsPage')));
const Settings = Loadable(lazy(() => import('views/pages/settings')));
const Products = Loadable(lazy(() => import('views/pages/Products')));
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const ExportPage = Loadable(lazy(() => import('views/pages/Export/ExportPage')));
const Maintenance = Loadable(lazy(() => import('views/pages/Maintenance/MaintenancePage')));
const Production = Loadable(lazy(() => import('views/pages/Production/ProductionPage')));
//const Orders = Loadable(lazy(() => import('views/pages/Orders/OrdersPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },

        {
            path: 'SpareParts',
            element: <SpareParts />
        },
        {
            path: 'Suppliers',
            element: <Suppliers />
        },
        {
            path: 'Partners',
            element: <Partners />
        },

        {
            path: 'Purchase',
            element: <Purchase />
        },

        {
            path: 'Planning',
            element: <Planning />
        },

        {
            path: 'Export',
            element: <ExportPage />
        },
        {
            path: 'maintenance',
            element: <Maintenance />
        },
        {
            path: 'production',
            element: <Production />
        },
        {
            path: 'orders',
            element: <Orders />
        },

        {
            path: 'users',
            element: <InternalUsers />
        },

        {
            path: 'events',
            element: <EventsPage />
        },

        {
            path: 'settings',
            element: <Settings />
        },
        {
            path: 'Products',
            element: <Products />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
