/* eslint-disable no-unused-vars */
// project imports
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import MinimalLayout from 'layout/MinimalLayout';
// login routing
const HomePage = Loadable(lazy(() => import('views/pages/HomePage/homePage')));

// ==============================|| Unauthorized ROUTING ||============================== //

const HomeRoute = {
    path: '/',
    element: <MinimalLayout />,
    children: [{ path: 'home', element: <HomePage /> }]
};

export default HomeRoute;
