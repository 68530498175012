import * as TYPES from './types';

const initialState = {
    SpareParts_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    SpareParts: {},
    TotalSpareParts: 0
};

export default function SparePartsReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.GET_SPAREPART_RECEIVE: {
            return {
                ...state,
                SpareParts_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }

        case TYPES.CREATE_SPAREPART_RECEIVE: {
            return {
                ...state,
                SpareParts_list: [...state.SpareParts_list, action?.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        case TYPES.EDIT_SPAREPART_RECEIVE: {
            return {
                ...state,
                SpareParts: action.payload,
                SpareParts_list: state.SpareParts_list.map((SpareParts) => {
                    if (SpareParts.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return SpareParts;
                    }
                })
            };
        }

        case TYPES.DELETE_SPAREPART_RECEIVE: {
            return {
                ...state,
                SpareParts_list: state.SpareParts_list.filter((SpareParts) => SpareParts.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        // get total spare parts
        case TYPES.GET_TOTAL_SPAREPART_RECEIVE: {
            return {
                ...state,
                TotalSpareParts: action.payload
            };
        }
        default:
            return state;
    }
}
