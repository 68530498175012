//const  myBaseUrl = "http://128.199.32.128/";

import moment from 'moment';

//const myBaseUrl = "http://localhost:3000/";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

//Random number < 100
const imageNumber = Math.floor(Math.random() * 100);

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 275;
export const appDrawerWidth = 320;
// USERS ROLES
const USER = 'USER';
const ADMIN = 'ADMIN';
const SUPERADMIN = 'SUPERADMIN';

// USERS STATUS
const ACTIVE = 'ACTIVE';
const BLOCKED = 'BLOCKED';
const INACTIVE = 'INACTIVE';
//const INACTIVE = 'INACTIVE';
const DELETED = 'DELETED';

//Requests Status
const NEW = 'NEW';
const PENDING = 'PENDING';
const PLANED = 'PLANED';
const REJECTED = 'REJECTED';
const CANCELED = 'CANCELED';
const DONE = 'DONE';

//Requests Types
const IMMOBILIER = 'IMMOBILIER';
const TAPIS = 'TAPIS';
const MATELAS = 'MATELAS';
const AUTO = 'AUTO';

//Options Types
const SELECT = 'SELECT';
const SURFACE = 'SURFACE';
const NBPLACE = 'NBPLACE';
const NBPLACE_SURFACE = 'NBPLACE_SURFACE';
const TYPE_NBPLACE_SURFACE = 'TYPE_NBPLACE_SURFACE';

// Payment Types
const IN = 'IN';
const OUT = 'OUT';

export const tasks = [
    'Triangle',
    'Overlap',
    'Embroidery',
    'Patches',
    'Ironing',
    'Packaging',
    'Export',
    'Cut pieces',
    'Finished pieces',
    'Thread',
    'Zipper',
    'Washing',
    'Dyeing',
    'Button',
    'Presser',
    'Eyeglass',
    'Toppe'
];
export const usersRoles = [
    { id: USER, name: 'Utilisateur' },
    { id: ADMIN, name: 'Administrateur' },
    { id: SUPERADMIN, name: 'Super Administrateur' }
];

export const usersStatus = [
    { id: ACTIVE, name: 'Actif' },
    { id: BLOCKED, name: 'Bloqué' },
    { id: INACTIVE, name: 'Inactif' },
    { id: DELETED, name: 'Supprimé' }
];

export const requestsStatus = [
    { id: NEW, name: 'Nouveau' },
    { id: PENDING, name: 'En attente' },
    { id: PLANED, name: 'Planifié' },
    { id: REJECTED, name: 'Rejeté' },
    { id: CANCELED, name: 'Annulé' },
    { id: DONE, name: 'Terminé' }
];

export const requestsTypes = [
    { id: IMMOBILIER, name: 'Immobilier' },
    { id: TAPIS, name: 'Tapis' },
    { id: MATELAS, name: 'Matelas' },
    { id: AUTO, name: 'Véhicule' }
];

export const optionsTypes = [
    { id: SELECT, name: 'Sélection (Titre / Prix)' },
    { id: SURFACE, name: 'Surface (Longueur * Largeur / Prix)' },
    { id: NBPLACE, name: 'Nombre de place (Nombre de place / Prix)' },
    { id: NBPLACE_SURFACE, name: 'Nombre de place et surface (Nombre de place / Surface / Prix)' },
    { id: TYPE_NBPLACE_SURFACE, name: 'Type et nombre de place et surface (Type / Nombre de place / Prix)' }
];

export const projectsStatus = [{ id: DONE, name: 'Projets fini' }];

export const paymentTypes = [
    { id: IN, name: 'Entrée' },
    { id: OUT, name: 'Sortie' }
];

export const initialSupplier = {
    id: null,
    name: '',
    company: '',
    address: '',
    phone: '',
    email: '',
    note: ''
};

export const initialPartners = {
    id: null,
    manufacture: '',
    company: '',
    address: '',
    phone: '',
    email: '',
    note: ''
};
export const initialAchat = {
    SupplierId: '',
    numcommande: '',

    unit: 'TND',

    ProductItems: [{ productID: '', sparePartID: '', source: '', quantity: 0.0, tarif: 0.0 }]
};

export const initialProduction = {
    ItemC1: [{ quantity: 0.0, tarif: 0.0 }],
    ItemC2: [{ quantity: 0.0, tarif: 0.0 }],
    ItemC3: [{ quantity: 0.0, tarif: 0.0 }]
};

export const initialMentenance = {
    note: '',
    MaintenanceItems: [{ sparePartID: '', quantity: 0.0, tarif: 0.0 }]
};

export const initialExport = {
    date: '',
    num: '',
    ExportItem: [{ productID: '', quantity: 0.0, priceU: 0.0, priceT: 0.0, weight: 0.0, note: '' }],

    Matiere: '',
    note: ''
};

// products Categories
const CUTPIECES = 'CUTPIECES';
const COMPLETEPIECES = 'COMPLETEPIECES';
const PACKAGING = 'PACKAGING';
const THREADS = 'THREADS';
const ACCESSOIRE = 'ACCESSOIRE';

export const InPutCategories = [
    { id: CUTPIECES, name: 'Pièce coupée' },
    { id: COMPLETEPIECES, name: 'Pièce complète' }
];

export const OutPutCategories = [
    {
        id: THREADS,
        name: 'Fil'
    },
    {
        id: CUTPIECES,
        name: 'Pièce coupée'
    },
    {
        id: COMPLETEPIECES,
        name: 'Pièce complète'
    },
    {
        id: PACKAGING,
        name: 'Emballage'
    },

    {
        id: ACCESSOIRE,
        name: 'Accessoire'
    }
];
export const initialPlanning = {
    id: null,
    PartnerId: '',
    OutPut: [{ categoryOutPut: '', productOutPutID: '', quantityOutPut: 0.0 }],
    InPut: [{}],
    Slice: [{ dateSlice: '', quantitySlice: 0.0 }],
    tasks: [],
    rate: 0
};
export const initialProduct = {
    id: null,
    name: '',
    ref: '',
    quantity: 0.0,
    minStock: 0.0,
    note: ''
};

export const initialPiece = {
    id: null,
    nom: '',
    etat_stock: '0,00'
};

export const initialCutPiece = {
    id: null,
    name: '',
    ref: '',
    color: '',
    height: 0.0,
    width: 0.0
};

export const initialCompletePiece = {
    id: null,
    name: '',
    ref: '',
    height: 0.0,
    width: 0.0
};
export const initialPackaging = {
    id: null,
    name: '',
    ref: '',
    material: '',
    quantity: 0.0,
    note: ''
};
export const initialAddress = {
    id: null,
    address: '',
    lat: '',
    lng: ''
};

export const initialImage = {
    id: null,
    filename: '',
    mimetype: '',
    encoding: ''
};

export const initialUser = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    address: '',
    role: '',
    image: `https://i.pravatar.cc/200?img=${imageNumber}`
};

export const encodeUserNameAndPass = (userName, password) => {
    var decodedStringBtoA = `[userName]${userName}[password]${password}`;
    return btoa(decodedStringBtoA);
};

export const decodeUserNameAndPass = (str) => {
    return atob(str);
};
