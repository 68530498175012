import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import { drawerWidth } from 'utils/Constants';
// import vector image from assets
// import SidebarImage from '../../../assets/images/Vector.png';
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block' } }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mx: 'auto'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#fff',
                            fontFamily: 'Poppins',
                            fontSize: '32px',
                            lineHeight: '48px',
                            fontWeight: '700',
                            padding: '2px 0px 2px 0px'
                        }}
                    >
                        PULL TEX
                    </Typography>
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: '100vh',
                        paddingLeft: '20px',
                        paddingRight: '0px'
                    }}
                >
                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: '#04B23C',
                        color: theme.palette.text.white,
                        borderRight: 'none',
                        overflowY: 'hidden'
                    }
                }}
                ModalProps={{ keepMounted: true }}
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
