//Get achats
export const GET_ACHAT_REQUEST = 'GET_ACHAT_REQUEST';
export const GET_ACHAT_RECEIVE = 'GET_ACHAT_RECEIVE';
//create achat
export const CREATE_ACHAT_REQUEST = 'CREATE_ACHAT_REQUEST';
export const CREATE_ACHAT_RECEIVE = 'CREATE_ACHAT_RECEIVE';
//update achat
export const EDIT_ACHAT_REQUEST = 'EDIT_ACHAT_REQUEST';
export const EDIT_ACHAT_RECEIVE = 'EDIT_ACHAT_RECEIVE';
//delete achat
export const DELETE_ACHAT_REQUEST = 'DELETE_ACHAT_REQUEST';
export const DELETE_ACHAT_RECEIVE = 'DELETE_ACHAT_RECEIVE';

// Edit achat status
export const EDIT_ACHAT_STATUS_REQUEST = 'EDIT_ACHAT_STATUS_REQUEST';
export const EDIT_ACHAT_STATUS_RECEIVE = 'EDIT_ACHAT_STATUS_RECEIVE';

// get total achat
export const GET_TOTAL_ACHAT_REQUEST = 'GET_TOTAL_ACHAT_REQUEST';
export const GET_TOTAL_ACHAT_RECEIVE = 'GET_TOTAL_ACHAT_RECEIVE';

// get products
export const GET_ALLPRODUCTS_REQUEST = 'GET_ALLPRODUCTS_REQUEST';
export const GET_ALLPRODUCTS_RECEIVE = 'GET_ALLPRODUCTS_RECEIVE';

// get purchases by supplier id
export const GET_PURCHASES_BY_SUPPLIER_ID_REQUEST = 'GET_PURCHASES_BY_SUPPLIER_ID_REQUEST';
export const GET_PURCHASES_BY_SUPPLIER_ID_RECEIVE = 'GET_PURCHASES_BY_SUPPLIER_ID_RECEIVE';

// get total Purchases by day
export const GET_TOTAL_PURCHASES_BY_DAY_REQUEST = 'GET_TOTAL_PURCHASES_BY_DAY_REQUEST';
export const GET_TOTAL_PURCHASES_BY_DAY_RECEIVE = 'GET_TOTAL_PURCHASES_BY_DAY_RECEIVE';

// get total Purchases
export const GET_TOTAL_PURCHASES_REQUEST = 'GET_TOTAL_PURCHASES_REQUEST';
export const GET_TOTAL_PURCHASES_RECEIVE = 'GET_TOTAL_PURCHASES_RECEIVE';

// get total Purchases
export const GET_TOTAL_PURCHASES_TND_REQUEST = 'GET_TOTAL_PURCHASES_TND_REQUEST';
export const GET_TOTAL_PURCHASES_TND_RECEIVE = 'GET_TOTAL_PURCHASES_TND_RECEIVE';
