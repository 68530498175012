import { combineReducers } from 'redux';
import theme from './theme/reducer';
import auth from './auth/reducer';
import users from './users/reducer';
import loader from './loader/reducer';
import snackbar from './snackbar/reducer';
import notifications from './notifications/reducer';
import events from './events/reducer';
import loading from './loading/reducer';
import Suppliers from './Suppliers/reducer';
import Partners from './Partners/reducer';
import achat from './achat/reducer';
import SparePartsReducer from './SpareParts/reducer';
import PlanningReducer from './Planning/reducer';
import ProductsReducer from './Products/reducer';
import CategoriesReducer from './Categories/reducer';
import ExportReducer from './Export/reducer';
import maintenancesReducer from './maintenance/reducer';
import production from './production/reducer';
import Orders from './Order/reducer';
export default combineReducers({
    theme,
    snackbar,
    loader,
    auth,
    users,
    notifications,
    events,
    loading,
    Suppliers,
    Partners,
    achat,
    SparePartsReducer,
    PlanningReducer,
    ProductsReducer,
    CategoriesReducer,
    ExportReducer,
    maintenancesReducer,
    production,
    Orders
});
