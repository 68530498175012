// get outPut
export const GET_OUTPUT_REQUEST = 'GET_OUTPUT_REQUEST';
export const GET_OUTPUT_RECEIVE = 'GET_OUTPUT_RECEIVE';
//get planning
export const GET_PLANNINGS_REQUEST = 'GET_PLANNINGS_REQUEST';
export const GET_PLANNINGS_RECEIVE = 'GET_PLANNINGS_RECEIVE';
//create planning
export const CREATE_PLANNING_REQUEST = 'CREATE_PLANNING_REQUEST';
export const CREATE_PLANNING_RECEIVE = 'CREATE_PLANNING_RECEIVE';
// get all planning by partner Id
export const GET_PLANNINGS_BY_PARTNER_ID_REQUEST = 'GET_PLANNINGS_BY_PARTNER_ID_REQUEST';
export const GET_PLANNINGS_BY_PARTNER_ID_RECEIVE = 'GET_PLANNINGS_BY_PARTNER_ID_RECEIVE';

// update planning by id
export const UPDATE_PLANNING_BY_ID_REQUEST = 'UPDATE_PLANNING_BY_ID_REQUEST';
export const UPDATE_PLANNING_BY_ID_RECEIVE = 'UPDATE_PLANNING_BY_ID_RECEIVE';

// delete planning by id
export const DELETE_PLANNING_BY_ID_REQUEST = 'DELETE_PLANNING_BY_ID_REQUEST';
export const DELETE_PLANNING_BY_ID_RECEIVE = 'DELETE_PLANNING_BY_ID_RECEIVE';

// get outPut Product by category
export const GET_OUTPUT_PRODUCT_BY_CATEGORY_REQUEST = 'GET_OUTPUT_PRODUCT_BY_CATEGORY_REQUEST';
export const GET_OUTPUT_PRODUCT_BY_CATEGORY_RECEIVE = 'GET_OUTPUT_PRODUCT_BY_CATEGORY_RECEIVE';

// get total plannings
export const GET_TOTAL_PLANNING_REQUEST = 'GET_TOTAL_PLANNING_REQUEST';
export const GET_TOTAL_PLANNING_RECEIVE = 'GET_TOTAL_PLANNING_RECEIVE';

// get LastInput
export const GET_LAST_INPUT_REQUEST = 'GET_LAST_INPUT_REQUEST';
export const GET_LAST_INPUT_RECEIVE = 'GET_LAST_INPUT_RECEIVE';
