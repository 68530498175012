import * as TYPES from './types';

const initialState = {
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    Production: {},
    Production_list: []
};

export default function ProductionsReducer(state = initialState, action) {
    switch (action.type) {
        //Get Productions
        case TYPES.GET_PRODUCTION_RECEIVE: {
            return {
                ...state,
                Production_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create Production
        case TYPES.CREATE_PRODUCTION_RECEIVE: {
            const newData = action.payload.data;
            return {
                ...state,
                Production_list: [...state.Production_list, ...newData],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit Production

        case TYPES.EDIT_PRODUCTION_RECEIVE: {
            return {
                ...state,
                Production: action.payload,

                Production_list: state.Production_list.map((Production) => {
                    if (Production?.id === action?.payload.id) {
                        return action.payload;
                    } else {
                        return Production;
                    }
                })
            };
        }

        case TYPES.DELETE_PRODUCTION_RECEIVE: {
            return {
                ...state,
                Production_list: state.Production_list.filter((Production) => Production.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
