import * as TYPES from './types';

const initialState = {
    Orders_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    Order: {}
};

export default function OrdersReducer(state = initialState, action) {
    switch (action.type) {
        //Get Orders
        case TYPES.GET_ORDERS_RECEIVE: {
            return {
                ...state,
                Orders_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create Order
        case TYPES.CREATE_ORDER_RECEIVE: {
            return {
                ...state,
                Orders_list: [...state.Orders_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit Order
        case TYPES.EDIT_ORDER_RECEIVE: {
            return {
                ...state,
                Order: action.payload,
                Orders_list: state.Orders_list.map((Order) => {
                    if (Order.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return Order;
                    }
                })
            };
        }
        //Delete Order
        case TYPES.DELETE_ORDER_RECEIVE: {
            return {
                ...state,
                Orders_list: state.Orders_list.filter((Order) => Order.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //get Order
        case TYPES.GET_ORDER_RECEIVE: {
            return {
                ...state,
                Order: action.payload
            };
        }

        default:
            return state;
    }
}
