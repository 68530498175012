import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Orders
export function* getOrdersSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getOrdersRequest, payload);

        yield put(ACTIONS.getOrdersReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des Orders');
    }
}

// Create Order
export function* createOrdersaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createOrderRequest, payload);
        yield put(ACTIONS.createOrderReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit Order
export function* editOrdersaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editOrderRequest, payload);
        yield put(ACTIONS.editOrderReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Order
export function* deleteOrdersaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteOrderRequest, id);
        yield put(ACTIONS.deleteOrderReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get nom Order
export function* getOrdersaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getNomOrderRequest, payload);
        yield put(ACTIONS.getNomOrderReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* OrdersSagas() {
    yield takeLatest(TYPES.GET_ORDERS_REQUEST, getOrdersSaga);
    yield takeLatest(TYPES.CREATE_ORDER_REQUEST, createOrdersaga);
    yield takeLatest(TYPES.DELETE_ORDER_REQUEST, deleteOrdersaga);
    yield takeLatest(TYPES.GET_ORDER_REQUEST, getOrdersaga);
    yield takeLatest(TYPES.EDIT_ORDER_REQUEST, editOrdersaga);
}
