import PropTypes from 'prop-types';
import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// project imports

import ProfileSection from './ProfileSection';

// assets
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 40,
                    display: 'flex',
                    backgroundColor: 'red',
                    [theme.breakpoints.down('md')]: {
                        width: '100%'
                    }
                }}
            >
                <MenuOpenIcon
                    stroke={1.5}
                    fontSize="large"
                    sx={{
                        color: '#2227EC',
                        '&:hover': {
                            color: '#0a10fa'
                        }
                    }}
                    onClick={handleLeftDrawerToggle}
                />
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
