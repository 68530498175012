let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
    BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
    BACKEND_SERVER = 'http://localhost:5000/';
}

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Poppins', sans-serif`,

    API_SERVER: BACKEND_SERVER
};

export default config;
