import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';
// Get output
export function* getOutPutSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getOutPutRequest, payload);

        yield put(ACTIONS.getOutPutReceive(data));
        yield put(setLoadingStatus(false));
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
    }
}
//create planning
export function* createPlanningSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createPlanningRequest, payload);
        yield put(ACTIONS.createPlanningReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('Create planning success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
    }
}
//get planning
export function* getPlanningsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPlanningsRequest, payload);
        yield put(ACTIONS.getPlanningsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
        yield put(setLoadingStatus(false));
    }
}
// get all plannings by partner id
export function* getPlanningsByPartnerIdSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPlanningsByPartnerIdRequest, id);
        yield put(ACTIONS.getPlanningsByPartnerIdReceive(data));
        yield put(setLoadingStatus(false));
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
    }
}

//update planning by id
export function* updatePlanningByIdSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updatePlanningByIdRequest, payload);

        yield put(ACTIONS.updatePlanningByIdReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('Update planning success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
    }
}

// delete planning by id
export function* deletePlanningByIdSaga({ id, setOpenDeleteConfirmation }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deletePlanningByIdRequest, id);
        yield put(ACTIONS.deletePlanningByIdReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('Delete planning success');
        setTimeout(() => {
            setOpenDeleteConfirmation(false);
        }, 500);
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
    }
}
// get outPut Product by category
export function* getOutPutProductByCategorySaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getOutPutProductByCategoryRequest, payload);
        yield put(ACTIONS.getOutPutProductsByCategoryReceive(data));
        yield put(setLoadingStatus(false));
    } catch (error) {
        dispatchSnackbarError(error?.response?.data);
        yield put(setLoadingStatus(false));
    }
}

// get total plannings
export function* getTotalPlanningsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTotalPlanningsRequest, payload);

        yield put(ACTIONS.getTotalPlanningsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (error) {
        dispatchSnackbarError(error?.response);
    }
}

// get LastInput
export function* getLastInputsSaga() {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getLastInputRequest);
        yield put(ACTIONS.getLastInputReceive(data));

        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}

export function* PlanningSaga() {
    yield takeLatest(TYPES.GET_OUTPUT_REQUEST, getOutPutSaga);
    yield takeLatest(TYPES.CREATE_PLANNING_REQUEST, createPlanningSaga);
    yield takeLatest(TYPES.GET_PLANNINGS_REQUEST, getPlanningsSaga);
    yield takeLatest(TYPES.GET_PLANNINGS_BY_PARTNER_ID_REQUEST, getPlanningsByPartnerIdSaga);
    yield takeLatest(TYPES.UPDATE_PLANNING_BY_ID_REQUEST, updatePlanningByIdSaga);
    yield takeLatest(TYPES.DELETE_PLANNING_BY_ID_REQUEST, deletePlanningByIdSaga);
    yield takeLatest(TYPES.GET_OUTPUT_PRODUCT_BY_CATEGORY_REQUEST, getOutPutProductByCategorySaga);
    yield takeLatest(TYPES.GET_TOTAL_PLANNING_REQUEST, getTotalPlanningsSaga);
    yield takeLatest(TYPES.GET_LAST_INPUT_REQUEST, getLastInputsSaga);
}
