import * as TYPES from './types';

const initialState = {
    Suppliers_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltered: 0,
    Supplier: {}
};

export default function SuppliersReducer(state = initialState, action) {
    switch (action.type) {
        //Get Suppliers
        case TYPES.GET_SUPPLIERS_RECEIVE: {
            return {
                ...state,
                Suppliers_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create Supplier
        case TYPES.CREATE_SUPPLIER_RECEIVE: {
            return {
                ...state,
                Suppliers_list: [...state.Suppliers_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit Supplier
        case TYPES.EDIT_SUPPLIER_RECEIVE: {
            return {
                ...state,
                Supplier: action.payload,
                Suppliers_list: state.Suppliers_list.map((Supplier) => {
                    if (Supplier.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return Supplier;
                    }
                })
            };
        }
        //Delete Supplier
        case TYPES.DELETE_SUPPLIER_RECEIVE: {
            return {
                ...state,
                Suppliers_list: state.Suppliers_list.filter((Supplier) => Supplier.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //get Supplier
        case TYPES.GET_SUPPLIER_RECEIVE: {
            return {
                ...state,
                Supplier: action.payload
            };
        }

        default:
            return state;
    }
}
