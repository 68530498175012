import { axiosInstance } from '../../network';

export const getSparePartsRequest = async (payload) => {
    return await axiosInstance.get(`/api/SpareParts`, { params: payload });
};

export const createSparePartRequest = async (payload) => {
    return await axiosInstance.post(`/api/SpareParts/create`, payload);
};

export const updateSparePartRequest = async (payload) => {
    return await axiosInstance.patch(`/api/SpareParts/${payload.id}`, payload);
};

export const deleteSparePartRequest = async (id) => {
    return await axiosInstance.delete(`/api/SpareParts/delete/${id}`);
};

// get total spare parts
export const getTotalSparePartsRequest = async (payload) => {
    return await axiosInstance.get(`/api/SpareParts/total`, { params: payload });
};
