/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Autocomplete
} from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';

import { gridSpacing } from 'utils/Constants';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProductsRequest } from 'store/achat/actions';
function FormPartners({ formData, formErrors, handleSetFormData }) {
    const dispatch = useDispatch();
    const [payload, setPayload] = useState({
        search: '',
        searchByProduct: '',
        type: 0,
        page: 0,
        limit: 10
    });
    const { products_list } = useSelector((state) => state.achat);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAllProductsRequest());
    }, [payload]);

    return (
        <React.Fragment>
            <form autoComplete="off">
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="date">{t('Date')}</InputLabel>
                            <OutlinedInput
                                id="date"
                                name="date"
                                type="date"
                                value={formData.date ? moment(formData.date).format('YYYY-MM-DD') : 'yyyy-MM-dd'}
                                onChange={(e) => handleSetFormData(e)}
                                label="Date"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="Deadline">{t('Deadline')}</InputLabel>
                            <OutlinedInput
                                id="deadline"
                                name="deadline"
                                type="date"
                                value={formData.deadline ? moment(formData.deadline).format('YYYY-MM-DD') : 'yyyy-MM-dd'}
                                onChange={(e) => handleSetFormData(e)}
                                label="Deadline"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="Order Number">{t('Order Number')} </InputLabel>
                            <OutlinedInput
                                id="num"
                                name="num"
                                type="text"
                                value={formData.num}
                                onChange={(e) => handleSetFormData(e)}
                                label="Numéro de commande"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <Autocomplete
                                defaultValue={products_list.find((product) => product.id === formData.productID) || null}
                                id="productID"
                                name="productID"
                                options={products_list.filter((product) => product.name)}
                                getOptionLabel={(product) => `${product.name} - ${product?.ref || ''}`}
                                isOptionEqualToValue={(option, value) => option === value || value === ''}
                                onChange={(e, value) => {
                                    const selectedValue = value !== null ? value.id : '';
                                    handleSetFormData({ target: { name: 'productID', value: selectedValue } });
                                }}
                                renderInput={(params) => <TextField {...params} label={t('Product')} variant="outlined" fullWidth />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" error={!!formErrors.quantity}>
                            <InputLabel htmlFor="quantity">{t('Quantity')} *</InputLabel>
                            <OutlinedInput
                                id="quantity"
                                name="quantity"
                                type="number"
                                defaultValue={formData?.quantity}
                                onChange={(e) => handleSetFormData(e)}
                                label="Quantité *"
                                inputProps={{
                                    step: '0.001',
                                    min: 0
                                }}
                            />
                            {formErrors.quantity && <FormHelperText>{formErrors.quantity}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    );
}

FormPartners.propTypes = {
    formData: PropTypes.object.isRequired,
    formErrors: PropTypes.object.isRequired,
    handleSetFormData: PropTypes.func.isRequired
};

export default FormPartners;
