import { axiosInstance } from '../../network';

//Get Orders
export const getOrdersRequest = async (payload) => {
    return await axiosInstance.get(`/api/order/`, { params: payload });
};
//Create Order
export const createOrderRequest = async (payload) => {
    return await axiosInstance.post(`/api/order/create`, payload);
};

//Edit Order
export const editOrderRequest = async (payload) => {
    return await axiosInstance.patch(`/api/order/${payload.id}`, payload);
};
//Delete Order
export const deleteOrderRequest = async (id) => {
    return await axiosInstance.delete(`/api/order/delete/${id}`);
};

//Delete Order
export const getNomOrderRequest = async (id) => {
    return await axiosInstance.delete(`/api/order/nom/${id}`);
};
