import { axiosInstance } from '../../network';

export const getMaintenanceRequest = async (payload) => {
    return await axiosInstance.get(`/api/Maintenance`, { params: payload });
};

export const createMaintenanceRequest = async (payload) => {
    return await axiosInstance.post(`/api/Maintenance/create`, payload);
};

export const deleteMaintenanceRequest = async (id) => {
    return await axiosInstance.delete(`/api/Maintenance/delete/${id}`);
};

export const editMaintenanceRequest = async (payload) => {
    return await axiosInstance.patch(`/api/Maintenance/${payload.id}`, payload);
};
