import { axiosInstance } from '../../network';

//Get Partners
export const getPartnersRequest = async (payload) => {
    return await axiosInstance.get(`/api/Partners/`, { params: payload });
};
//Create Partner
export const createPartnerRequest = async (payload) => {
    return await axiosInstance.post(`/api/Partners/create`, payload);
};

//Edit Partner
export const editPartnerRequest = async (payload) => {
    return await axiosInstance.patch(`/api/Partners/${payload.id}`, payload);
};
//Delete Partner
export const deletePartnerRequest = async (id) => {
    return await axiosInstance.delete(`/api/Partners/delete/${id}`);
};

//Delete Partner
export const getNomPartnerRequest = async (id) => {
    return await axiosInstance.delete(`/api/Partners/nom/${id}`);
};
