import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

export function* getSparePartSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getSparePartsRequest, payload);

        yield put(ACTIONS.getSparePartsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* createSparePartSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createSparePartRequest, payload);
        yield put(ACTIONS.createSparePartReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* editSparePartSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateSparePartRequest, payload);
        yield put(ACTIONS.updateSparePartReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* deleteSparePartSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteSparePartRequest, id);
        yield put(ACTIONS.deleteSparePartReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// get total spare parts
export function* getTotalSparePartsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTotalSparePartsRequest, payload);
        yield put(ACTIONS.getTotalSparePartsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response);
    }
}

export function* SparePartSaga() {
    yield takeLatest(TYPES.GET_SPAREPART_REQUEST, getSparePartSaga);
    yield takeLatest(TYPES.CREATE_SPAREPART_REQUEST, createSparePartSaga);
    yield takeLatest(TYPES.EDIT_SPAREPART_REQUEST, editSparePartSaga);
    yield takeLatest(TYPES.DELETE_SPAREPART_REQUEST, deleteSparePartSaga);
    yield takeLatest(TYPES.GET_TOTAL_SPAREPART_REQUEST, getTotalSparePartsSaga);
}
