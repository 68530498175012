// material-ui
import { Typography } from '@mui/material';
import React from 'react';
// project imports
import NavGroup from './NavGroup';
//import menuItem from 'menu-items';
import getUserDataRequest from 'store/users/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconDashboard, IconCoinEuro, IconAd2, IconAdOff, IconChecklist, IconTools } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HandymanIcon from '@mui/icons-material/Handyman';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { useTranslation } from 'react-i18next';
import InventoryIcon from '@mui/icons-material/Inventory';
const icons = {
    Groups2OutlinedIcon,
    CategoryIcon,
    IconDashboard,
    IconCoinEuro,
    DashboardIcon,
    IconAd2,
    IconAdOff,
    IconChecklist,
    IconTools,
    HandshakeOutlinedIcon,
    ReceiptLongIcon,
    PendingActionsIcon,
    AvTimerOutlinedIcon,
    SettingsOutlinedIcon,
    HandymanIcon,
    ExitToAppIcon,
    BuildOutlinedIcon,
    Diversity2Icon,
    PeopleOutlineIcon,
    InventoryIcon
};

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { t } = useTranslation();
    const menuItem = {
        items: [
            {
                id: 'dashboard',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'default',
                        title: t('Dashboard'),
                        type: 'item',
                        url: '/dashboard',
                        icon: icons.DashboardIcon,
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'Products',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Products',
                        title: t('Product'),
                        type: 'item',
                        icon: icons.CategoryIcon,
                        url: '/Products',
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'spareParts',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'spareParts',
                        title: t('Spare part'),
                        type: 'item',
                        icon: icons.HandymanIcon,
                        url: '/SpareParts',
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'maintenance',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'maintenance',
                        title: t('Maintenance'),
                        type: 'item',
                        icon: icons.BuildOutlinedIcon,
                        url: '/maintenance',
                        breadcrumbs: true
                    }
                ]
            },

            {
                id: 'Partenaires',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Partenaires',
                        title: t('Partners'),
                        type: 'item',
                        icon: icons.Groups2OutlinedIcon,
                        url: '/Partners',
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'Fournisseurs',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Fournisseurs',
                        title: t('Suppliers'),
                        type: 'item',
                        icon: icons.ReceiptLongIcon,
                        url: '/Suppliers',
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'Commandes',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Commandes',
                        title: t('Orders'),
                        type: 'item',
                        icon: icons.IconAd2,
                        url: '/orders',
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'Achats',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Achats',
                        title: t('Purchases'),
                        type: 'item',
                        icon: icons.HandshakeOutlinedIcon,
                        url: '/Purchase',
                        breadcrumbs: true
                    }
                ]
            },

            {
                id: 'Planification',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Planification',
                        title: t('Planning'),
                        type: 'item',
                        icon: icons.PendingActionsIcon,
                        url: '/Planning',
                        breadcrumbs: true
                    }
                ]
            },

            {
                id: 'Export',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Export',
                        title: t('Export'),
                        type: 'item',
                        icon: icons.ExitToAppIcon,
                        url: '/Export',
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'production',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'production',
                        title: t('Production'),
                        type: 'item',
                        icon: icons.Diversity2Icon,
                        url: '/production',
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'system',
                title: '',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'Historique',
                        title: t('History'),
                        type: 'item',
                        url: '/events',
                        icon: icons.AvTimerOutlinedIcon,
                        breadcrumbs: true
                    }
                ]
            },
            {
                id: 'paramètres',
                roles: ['ADMIN', 'SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'paramètres',
                        title: t('Settings'),
                        type: 'item',
                        icon: icons.SettingsOutlinedIcon,
                        url: '/settings',
                        breadcrumbs: true
                    }
                ]
            },

            {
                id: 'users',
                roles: ['SUPERADMIN'],
                type: 'group',
                children: [
                    {
                        id: 'users',
                        title: t('Users'),
                        type: 'item',
                        icon: icons.PeopleOutlineIcon,
                        url: '/users',
                        breadcrumbs: true
                    }
                ]
            }
        ]
    };
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.users);
    const role = userData ? userData.role : 'user';
    const filteredItems = menuItem.items.filter((item) => {
        // Condition to filter menu items based on role
        if (item.roles && item.roles.includes(role)) {
            return true;
        }
        return false;
    });

    const navItems = filteredItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            case 'link':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
