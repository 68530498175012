import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Avatar, ButtonBase, Chip, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export function TableContainerComponent({
    selectPartner,
    openForm,
    openDeleteConfirmation,
    page,
    totalFiltred,
    limit,
    setLimit,
    setPage,
    OrdersList
}) {
    const { t } = useTranslation();
    const columns = [
        {
            id: 'Date',
            label: t('Date'),
            align: 'center'
        },
        {
            id: 'Deadline',
            label: t('Deadline'),
            align: 'center'
        },
        {
            id: 'num',
            label: t('Order Number'),
            align: 'center'
        },

        {
            id: 'Product',
            label: t('Product'),
            align: 'center'
        },

        {
            id: 'Quantity',
            label: t('Quantity'),
            align: 'center'
        },

        {
            id: 'Status',
            label: t('Status'),
            align: 'center'
        },

        {
            id: 'Action',
            label: t('Action'),
            align: 'center'
        }
    ];
    const theme = useTheme();
    const { loading } = useSelector((state) => state.loading);

    const handleChangePage = (Partner, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (Partner) => {
        setLimit(Partner.target.value);
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#ffff',
            color: theme.palette.common.black,
            fontSize: '15px',
            fontWeight: '600px'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell align="center" colSpan={columns.length}>
                                    <span className="api_loading">
                                        <img src="/loading.gif" width={50} alt="loader" />
                                    </span>
                                </TableCell>
                            </TableRow>
                        ) : OrdersList?.length > 0 ? (
                            OrdersList?.map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell align="center">
                                        <Typography variant="subtitle2">
                                            {row.date ? moment(row.date).format('DD/MM/YYYY') : '_'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle2">
                                            {' '}
                                            {row.deadline ? (
                                                <Chip
                                                    label={moment(row.deadline).format('DD/MM/YYYY')}
                                                    sx={{
                                                        backgroundColor:
                                                            moment(row.deadline).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
                                                                ? '#F44336'
                                                                : '#4CAF50',
                                                        color: 'white'
                                                    }}
                                                />
                                            ) : (
                                                '_'
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle2"> {row.num ? row.num : '_'}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle2">
                                            {' '}
                                            {row.product ? row.product.name + ' - ' + row.product.ref : '_'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle2"> {row.quantity ? row.quantity : '_'}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="subtitle2">
                                            {row.status ? (
                                                <Chip
                                                    label={row.status}
                                                    sx={{
                                                        backgroundColor:
                                                            row.status === 'PENDING'
                                                                ? '#FFC107'
                                                                : row.status === 'FINISHED'
                                                                ? '#4CAF50'
                                                                : '#F44336',
                                                        color: 'white'
                                                    }}
                                                />
                                            ) : (
                                                '_'
                                            )}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align="center">
                                        <TuneOutlinedIcon
                                            onClick={() => {
                                                selectPartner(row);
                                                openForm();
                                            }}
                                            sx={{
                                                color: '#19A84C',
                                                '&:hover': {
                                                    color: '#127a37'
                                                }
                                            }}
                                            stroke={1}
                                        />

                                        <DeleteOutlineOutlinedIcon
                                            onClick={() => {
                                                selectPartner(row);
                                                openDeleteConfirmation();
                                            }}
                                            sx={{
                                                color: '#FF0808',
                                                '&:hover': {
                                                    color: '#b30505'
                                                }
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell align="center" colSpan={columns.length}>
                                    <span className="api_no_data">
                                        <p>{t('NoData')}</p>
                                    </span>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalFiltred || 0}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('TablePaginationLabel')}
            />
        </Paper>
    );
}

TableContainerComponent.propTypes = {
    OrdersList: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    selectPartner: PropTypes.func,
    openDeleteConfirmation: PropTypes.func,
    pageCount: PropTypes.number.isRequired,
    totalFiltred: PropTypes.number.isRequired,
    limit: PropTypes.number,
    setLimit: PropTypes.func,
    setPage: PropTypes.func,
    openHistory: PropTypes.func
};

export default TableContainerComponent;
