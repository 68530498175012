import React, { useState } from 'react';
import i18n from '../i18n';
import LanguageIcon from '@mui/icons-material/Language';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconLogout } from '@tabler/icons';
import { IconWorld } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutRequest } from 'store/auth/actions';
const LanguageSelector = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const chooseLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
        handleClose();
    };

    const handleLogout = () => {
        dispatch(logoutRequest());
        setTimeout(() => {
            navigate('/home', { replace: true });
        }, 1000);
    };

    return (
        <div style={{ paddingRight: '10px', paddingTop: '10px', display: 'flex', alignItems: 'center' }}>
            <IconWorld onClick={handleClick} style={{ cursor: 'pointer' }} stroke={1.5} size="1.3rem" />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => chooseLanguage('fr')}>Français</MenuItem>
                <MenuItem onClick={() => chooseLanguage('en')}>English</MenuItem>
                <MenuItem onClick={() => chooseLanguage('it')}>Italian</MenuItem>
            </Menu>

            <IconLogout style={{ cursor: 'pointer' }} onClick={handleLogout} stroke={1.5} size="1.3rem" />
        </div>
    );
};

export default LanguageSelector;
