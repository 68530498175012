import * as TYPES from './types';

const initialState = {
    pageCount: 0,
    currentPage: 0,
    totalFiltered: 0,
    Partner: {},
    listPlanning: [],
    outPutList: [],
    planning: {},
    Plannings: [],
    TotalPlannings: 0,
    LastInput: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_OUTPUT_RECEIVE: {
            return {
                ...state,
                outPutList: action.payload
            };
        }
        case TYPES.GET_PLANNINGS_RECEIVE: {
            return {
                ...state,
                listPlanning: action.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        case TYPES.CREATE_PLANNING_RECEIVE: {
            return {
                ...state,
                listPlanning: [...state.listPlanning, action.payload]
            };
        }

        case TYPES.GET_PLANNINGS_BY_PARTNER_ID_RECEIVE: {
            return {
                ...state,
                Plannings: action.payload
            };
        }

        //Edit Planning by id
        case TYPES.UPDATE_PLANNING_BY_ID_RECEIVE: {
            return {
                ...state,
                planning: action.payload,
                listPlanning: state.listPlanning.map((planning) => {
                    if (planning.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return planning;
                    }
                })
            };
        }
        // delete planning by id
        case TYPES.DELETE_PLANNING_BY_ID_RECEIVE: {
            return {
                ...state,
                listPlanning: state.listPlanning.filter((planning) => planning.id !== action.id)
            };
        }
        // get outPut Product by category
        case TYPES.GET_OUTPUT_PRODUCT_BY_CATEGORY_RECEIVE: {
            return {
                ...state,
                outPutList: action.payload
            };
        }

        // get total plannings
        case TYPES.GET_TOTAL_PLANNING_RECEIVE: {
            return {
                ...state,
                TotalPlannings: action.payload
            };
        }

        // get LastInput
        case TYPES.GET_LAST_INPUT_RECEIVE: {
            return {
                ...state,
                LastInput: action.payload
            };
        }

        default:
            return state;
    }
};
