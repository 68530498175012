export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_RECEIVE = 'GET_PRODUCTS_RECEIVE';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_RECEIVE = 'CREATE_PRODUCT_RECEIVE';

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_RECEIVE = ' EDIT_PRODUCT_RECEIVE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_RECEIVE = 'DELETE_PRODUCT_RECEIVE';

export const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST';
export const GET_ALL_PRODUCTS_RECEIVE = 'GET_ALL_PRODUCTS_RECEIVE';

export const GET_OUTPUT_PRODUCTS_REQUEST = 'GET_OUTPUT_PRODUCTS_REQUEST';
export const GET_OUTPUT_PRODUCTS_RECEIVE = 'GET_OUTPUT_PRODUCTS_RECEIVE';

export const GET_INPUT_PRODUCTS_REQUEST = 'GET_INPUT_PRODUCTS_REQUEST';
export const GET_INPUT_PRODUCTS_RECEIVE = 'GET_INPUT_PRODUCTS_RECEIVE';

// get total products
export const GET_TOTAL_PRODUCTS_REQUEST = 'GET_TOTAL_PRODUCTS_REQUEST';
export const GET_TOTAL_PRODUCTS_RECEIVE = 'GET_TOTAL_PRODUCTS_RECEIVE';

// get products out of stock
export const GET_PRODUCTS_OUT_OF_STOCK_REQUEST = 'GET_PRODUCTS_OUT_OF_STOCK_REQUEST';
export const GET_PRODUCTS_OUT_OF_STOCK_RECEIVE = 'GET_PRODUCTS_OUT_OF_STOCK_RECEIVE';
