import * as TYPES from './types';

// Get Partners
export const getPartnersRequest = (payload) => ({
    type: TYPES.GET_PARTNERS_REQUEST,
    payload
});
export const getPartnersReceive = (payload) => ({
    type: TYPES.GET_PARTNERS_RECEIVE,
    payload
});
//Create Partner
export const createPartnerRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_PARTNER_REQUEST,
    payload,
    closeModal
});
export const createPartnerReceive = (payload) => ({
    type: TYPES.CREATE_PARTNER_RECEIVE,
    payload
});
//Edit Partner
export const editPartnerRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_PARTNER_REQUEST,
    payload,
    closeModal
});
export const editPartnerReceive = (payload) => ({
    type: TYPES.EDIT_PARTNER_RECEIVE,
    payload
});

//Delete Partner
export const deletePartnerRequest = (id) => ({
    type: TYPES.DELETE_PARTNER_REQUEST,
    id
});
export const deletePartnerReceive = (id) => ({
    type: TYPES.DELETE_PARTNER_RECEIVE,
    id
});

// Get Partner by id
export const getNomPartnerRequest = (id) => ({
    type: TYPES.GET_PARTNER_REQUEST,
    id
});
export const getNomPartnerReceive = (id) => ({
    type: TYPES.GET_PARTNER_RECEIVE,
    id
});
