/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import TableContainerComponent from './Table';
import { useState } from 'react';
import FilterContainer from './Filter';
import { getOrdersRequest, deleteOrderRequest } from 'store/Order/actions';
import { useTheme } from '@mui/material/styles';
import { IconPlus } from '@tabler/icons';
import CreatePartner from './CreateOrder';
import EditPartner from './EditOrder';
import { getUserDataRequest } from 'store/users/actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Orders() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const { Orders_list, totalFiltred, pageCount } = useSelector((state) => state.Orders);
    const [payload, setPayload] = useState({
        search: '',
        type: 0,
        page: 0,
        limit: 10
    });

    useEffect(() => {
        dispatch(getOrdersRequest(payload));
    }, [payload]);

    const handleFilter = (data) => {
        setPayload({
            ...payload,
            search: data.search
        });
    };

    const handleDelete = () => {
        dispatch(deleteOrderRequest(selectedPartner.id));
        setOpenDeleteConfirmation(false);
    };
    const theme = useTheme();

    const allowedRoles = ['ADMIN', 'USER', 'SUPERADMIN'];
    const { userData } = useSelector((state) => state.users);

    useEffect(() => {
        if (userData.role) {
            if (allowedRoles && allowedRoles.length > 0 && !allowedRoles.includes(userData.role)) {
                navigate('/unauthorized', { replace: true });
            }
        } else {
            dispatch(getUserDataRequest());
        }
    }, [userData]);
    return (
        <>
            <Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h2" sx={{ color: '#049734' }} gutterBottom>
                        {t('Orders')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <FilterContainer payload={payload} setPayload={(data) => handleFilter(data)} />
                </Grid>
            </Grid>
            <TableContainerComponent
                OrdersList={Orders_list}
                selectPartner={(data) => setSelectedPartner(data)}
                openDeleteConfirmation={() => setOpenDeleteConfirmation(true)}
                openForm={() => setOpenForm(true)}
                page={payload.page}
                pageCount={pageCount || 0}
                totalFiltred={totalFiltred || 0}
                limit={payload.limit}
                setLimit={(data) => setPayload({ ...payload, limit: parseInt(data), page: 0 })}
                setPage={(data) => setPayload({ ...payload, page: data })}
            />
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <ButtonBase sx={{ borderRadius: '20px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            background: theme.palette.success.light,
                            color: theme.palette.success.dark,
                            '&:hover': {
                                background: theme.palette.primary.dark,
                                color: theme.palette.success.dark
                            }
                        }}
                        onClick={() => {
                            setSelectedPartner(null);
                            setOpenForm(true);
                        }}
                        color="inherit"
                    >
                        <IconPlus stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Grid>

            {openForm && <CreatePartner open={openForm} close={() => setOpenForm(false)} />}
            {openForm && selectedPartner && <EditPartner open={openForm} close={() => setOpenForm(false)} Partner={selectedPartner} />}
            {openDeleteConfirmation && (
                <Dialog open={openDeleteConfirmation}>
                    <DialogTitle id="modal-modal-title" sx={{ padding: '20px', fontSize: '20px', textAlign: 'center' }}>
                        {t('DeletePartner')}
                    </DialogTitle>
                    <DialogContent sx={{ padding: '20px' }}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {t('ConfirmDeletePartner')} <b>{selectedPartner.manufacture}</b> ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteConfirmation(false)} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={() => handleDelete()} color="error">
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default Orders;
