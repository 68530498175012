/* eslint-disable no-unused-vars */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormPartners from './FormOrder';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialPartners } from 'utils/Constants';
import { useTranslation } from 'react-i18next';
import { createOrderRequest } from 'store/Order/actions';

function CreatePartners({ open, close }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ ...initialPartners });
    const [formErrors, setFormErrors] = useState({});
    const validateForm = () => {
        let errors = {};
        // if (!formData.company) {
        //     errors.company = 'La société est obligatoire';
        // }
        // if (!formData.manufacture) {
        //     errors.manufacture = 'faconnier est obligatoire';
        // }

        // if (!formData.phone) {
        //     errors.phone = 'Numéro de téléphone est obligatoire';
        // }

        return errors;
    };
    const handleSetFormData = (e) => {
        const { name, value } = e.target;
        let parsedValue = value;

        if (name === 'quantity') {
            parsedValue = isNaN(value) ? value : parseFloat(value, 10);
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: parsedValue
        }));
    };
    const closeModal = () => {
        setFormData({});
        close();
    };
    const handleCreate = () => {
        const errors = validateForm();
        if (Object.keys(errors).length == 0) {
            dispatch(createOrderRequest(formData, closeModal));
        } else {
            setFormErrors(errors);
        }
    };

    return (
        <Dialog open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <DialogTitle id="modal-modal-title" sx={{ padding: '20px', fontSize: '20px', textAlign: 'center' }}>
                {t('AddOrder')}
            </DialogTitle>
            <DialogContent sx={{ padding: { xs: '15px', sm: '25px', md: '40px' } }}>
                <br />
                <FormPartners formData={formData} formErrors={formErrors} handleSetFormData={(e) => handleSetFormData(e)} />
            </DialogContent>

            <DialogActions>
                <Button
                    sx={{
                        backgroundColor: '#2c2d2e',
                        color: 'white',
                        '&:hover': {
                            background: ' #525861'
                        }
                    }}
                    onClick={close}
                    variant="primary"
                >
                    {t('Cancel')}
                </Button>
                <Button
                    sx={{
                        backgroundColor: '#049737',
                        color: 'white',
                        '&:hover': {
                            background: ' #06bd45'
                        }
                    }}
                    onClick={handleCreate}
                    variant="primary"
                >
                    {t('Confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CreatePartners.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
};

export default CreatePartners;
