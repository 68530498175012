import * as TYPES from './types';

// Get Products
export const getAllProductsRequest = (payload) => ({
    type: TYPES.GET_ALLPRODUCTS_REQUEST,
    payload
});
export const getAllProductsReceive = (payload) => ({
    type: TYPES.GET_ALLPRODUCTS_RECEIVE,
    payload
});

// Get achats
export const getAchatsRequest = (payload) => ({
    type: TYPES.GET_ACHAT_REQUEST,
    payload
});
export const getAchatsReceive = (payload) => ({
    type: TYPES.GET_ACHAT_RECEIVE,
    payload
});

//Create achat
export const createAchatRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_ACHAT_REQUEST,
    payload,
    closeModal
});
export const createAchatReceive = (payload) => ({
    type: TYPES.CREATE_ACHAT_RECEIVE,
    payload
});
//Edit achat
export const editAchatRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_ACHAT_REQUEST,
    payload,
    closeModal
});
export const editAchatReceive = (payload) => ({
    type: TYPES.EDIT_ACHAT_RECEIVE,
    payload
});

// Edit achat status
export const editAchatStatusRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_ACHAT_STATUS_REQUEST,
    payload,
    closeModal
});
export const editAchatStatusReceive = (payload) => ({
    type: TYPES.EDIT_ACHAT_STATUS_RECEIVE,
    payload
});

//Delete achat
export const deleteAchatRequest = (id) => ({
    type: TYPES.DELETE_ACHAT_REQUEST,
    id
});
export const deleteAchatReceive = (id) => ({
    type: TYPES.DELETE_ACHAT_RECEIVE,
    id
});

//get total achat
export const getTotalAchatRequest = (payload) => ({
    type: TYPES.GET_TOTAL_ACHAT_REQUEST,
    payload
});
export const getTotalAchatReceive = (payload) => ({
    type: TYPES.GET_TOTAL_ACHAT_RECEIVE,
    payload
});

// get purchases by supplier Id
export const getPurchasesBySupplierIdRequest = (id) => ({
    type: TYPES.GET_PURCHASES_BY_SUPPLIER_ID_REQUEST,
    id
});
export const getPurchasesBySupplierIdReceive = (payload) => ({
    type: TYPES.GET_PURCHASES_BY_SUPPLIER_ID_RECEIVE,
    payload
});

// get total Purchases
export const getTotalPurchasesRequest = (payload) => ({
    type: TYPES.GET_TOTAL_PURCHASES_REQUEST,
    payload
});
export const getTotalPurchasesReceive = (payload) => ({
    type: TYPES.GET_TOTAL_PURCHASES_RECEIVE,
    payload
});

// get total Purchases
export const getTotalPurchasesTNDRequest = (payload) => ({
    type: TYPES.GET_TOTAL_PURCHASES_TND_REQUEST,
    payload
});
export const getTotalPurchasesTNDReceive = (payload) => ({
    type: TYPES.GET_TOTAL_PURCHASES_TND_RECEIVE,
    payload
});

// get total Purchases by day
export const getTotalPurchasesByDayRequest = (payload) => ({
    type: TYPES.GET_TOTAL_PURCHASES_BY_DAY_REQUEST,
    payload
});
export const getTotalPurchasesByDayReceive = (payload) => ({
    type: TYPES.GET_TOTAL_PURCHASES_BY_DAY_RECEIVE,
    payload
});
