// get Orders
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_RECEIVE = 'GET_ORDERS_RECEIVE';
//create Order
export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_RECEIVE = 'CREATE_ORDER_RECEIVE';
// Edit Order
export const EDIT_ORDER_REQUEST = 'EDIT_ORDER_REQUEST';
export const EDIT_ORDER_RECEIVE = ' EDIT_ORDER_RECEIVE';
// delete Order
export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_RECEIVE = 'DELETE_ORDER_RECEIVE';
// get Order by id
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_RECEIVE = 'GET_ORDER_RECEIVE';
