import { axiosInstance } from '../../network';

//Get achats
export const getAchatsRequest = async (payload) => {
    return await axiosInstance.get(`achats`, { params: payload });
};

//Create achat
export const createAchatRequest = async (payload) => {
    return await axiosInstance.post(`achats/createachat`, payload);
};
//Delete achat
export const deleteAchatRequest = async (id) => {
    return await axiosInstance.delete(`achats/delete/${id}`);
};

//Edit achat
export const editAchatRequest = async (payload) => {
    return await axiosInstance.patch(`achats/${payload.id}`, payload);
};
//Edit achat status
export const editAchatStatusRequest = async (payload) => {
    return await axiosInstance.patch(`achats/updateStatutAchat/${payload.id}`, payload);
};

//get total achat
export const getTotalAchatRequest = async (payload) => {
    return await axiosInstance.get(`achats/totalAchat`, { params: payload });
};

//get products
export const getAllProductsRequest = async (payload) => {
    return await axiosInstance.get(`achats/Allproducts`, { params: payload });
};

// get purchases bu supplier Id
export const FindPurchasesBySupplierId = async (id) => {
    return await axiosInstance.get(`achats/supplier/${id}`);
};

// get total Purchases by day
export const getTotalPurchasesByDay = async (payload) => {
    return await axiosInstance.get(`achats/totalByWeek`, { params: payload });
};

// get total Purchases
export const getTotalPurchases = async (payload) => {
    return await axiosInstance.get(`achats/totalEuro`, { params: payload });
};

// get total Purchases
export const getTotalPurchasesTND = async (payload) => {
    return await axiosInstance.get(`achats/totalTND`, { params: payload });
};
