import { put, takeLatest } from 'redux-saga/effects';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError } from '../../utils/Shared';

// menu open
export function* menuOpenSaga({ payload }) {
    try {
        yield put(ACTIONS.menuOpenReceive(payload));
    } catch (err) {
        dispatchSnackbarError(err?.response?.data);
    }
}

// set menu
export function* setMenuSaga({ payload }) {
    try {
        yield put(ACTIONS.setMenuReceive(payload));
    } catch (err) {
        dispatchSnackbarError(err?.response?.data);
    }
}

// set font family
export function* setFontFamilySaga({ payload }) {
    try {
        yield put(ACTIONS.setFontFamilyReceive(payload));
    } catch (err) {
        dispatchSnackbarError(err?.response?.data);
    }
}

// set border radius
export function* setBorderRadiusSaga({ payload }) {
    try {
        yield put(ACTIONS.setBorderRadiusReceive(payload));
    } catch (err) {
        dispatchSnackbarError(err?.response?.data);
    }
}

export function* themeSagas() {
    yield takeLatest(TYPES.MENU_OPEN_REQUEST, menuOpenSaga);
    yield takeLatest(TYPES.SET_MENU_REQUEST, setMenuSaga);
    yield takeLatest(TYPES.SET_FONT_FAMILY_REQUEST, setFontFamilySaga);
    yield takeLatest(TYPES.SET_BORDER_RADIUS_REQUEST, setBorderRadiusSaga);
}
