import * as TYPES from './types';

const initialState = {
    achats_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    achat: {},
    totalAchat: [],
    totalAchatTND: [],
    products_list: [],
    HistoryOfPurchases: [],
    TotalPurchases: 0,
    TotalPurchasesByDay: {},
    TotalPurchasesByMonth: 0
};

export default function achatsReducer(state = initialState, action) {
    switch (action.type) {
        //Get Products
        case TYPES.GET_ALLPRODUCTS_RECEIVE: {
            return {
                ...state,
                products_list: action?.payload
            };
        }
        //Get achats
        case TYPES.GET_ACHAT_RECEIVE: {
            return {
                ...state,
                achats_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create achat
        case TYPES.CREATE_ACHAT_RECEIVE: {
            return {
                ...state,
                achats_list: [...state.achats_list, action?.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit achat
        case TYPES.EDIT_ACHAT_RECEIVE: {
            return {
                ...state,
                achat: action.payload,
                achats_list: state.achats_list.map((achat) => {
                    if (achat.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return achat;
                    }
                })
            };
        }

        // Edit achat status
        case TYPES.EDIT_ACHAT_STATUS_RECEIVE: {
            return {
                ...state,
                achat: action.payload,
                achats_list: state.achats_list.map((achat) => {
                    if (achat.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return achat;
                    }
                })
            };
        }

        //Delete achat
        case TYPES.DELETE_ACHAT_RECEIVE: {
            return {
                ...state,
                achats_list: state.achats_list.filter((achat) => achat.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        // get total achat Euro
        case TYPES.GET_TOTAL_ACHAT_RECEIVE: {
            return {
                ...state,
                totalAchat: action.payload
            };
        }

        // get total achat TND
        case TYPES.GET_TOTAL_PURCHASES_TND_RECEIVE: {
            return {
                ...state,
                totalAchatTND: action.payload
            };
        }

        //get purchases by supplierId
        case TYPES.GET_PURCHASES_BY_SUPPLIER_ID_RECEIVE: {
            return {
                ...state,
                HistoryOfPurchases: action.payload
            };
        }

        // get total Purchases by day
        case TYPES.GET_TOTAL_PURCHASES_BY_DAY_RECEIVE: {
            return {
                ...state,
                TotalPurchasesByDay: action.payload
            };
        }
        // get total purchases
        case TYPES.GET_TOTAL_PURCHASES_RECEIVE: {
            return {
                ...state,
                TotalPurchases: action.payload
            };
        }
        default:
            return state;
    }
}
