import { axiosInstance } from '../../network';

//Get Suppliers
export const getSuppliersRequest = async (payload) => {
    return await axiosInstance.get(`api/suppliers`, { params: payload });
};
//Create Supplier
export const createSupplierRequest = async (payload) => {
    return await axiosInstance.post(`api/suppliers/create`, payload);
};

//Edit Supplier
export const editSupplierRequest = async (payload) => {
    return await axiosInstance.patch(`api/suppliers/${payload.id}`, payload);
};
//Delete Supplier
export const deleteSupplierRequest = async (id) => {
    return await axiosInstance.delete(`api/suppliers/delete/${id}`);
};

//Delete Supplier
export const getNomSupplierRequest = async (id) => {
    return await axiosInstance.delete(`api/suppliers/nom/${id}`);
};
