import * as TYPES from './types';

const initialState = {
    Partners_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltered: 0,
    Partner: {}
};

export default function PartnersReducer(state = initialState, action) {
    switch (action.type) {
        //Get Partners
        case TYPES.GET_PARTNERS_RECEIVE: {
            return {
                ...state,
                Partners_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create Partner
        case TYPES.CREATE_PARTNER_RECEIVE: {
            return {
                ...state,
                Partners_list: [...state.Partners_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit Partner
        case TYPES.EDIT_PARTNER_RECEIVE: {
            return {
                ...state,
                Partner: action.payload,
                Partners_list: state.Partners_list.map((Partner) => {
                    if (Partner.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return Partner;
                    }
                })
            };
        }
        //Delete Partner
        case TYPES.DELETE_PARTNER_RECEIVE: {
            return {
                ...state,
                Partners_list: state.Partners_list.filter((Partner) => Partner.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //get Partner
        case TYPES.GET_PARTNER_RECEIVE: {
            return {
                ...state,
                Partner: action.payload
            };
        }

        default:
            return state;
    }
}
